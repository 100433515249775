@import '../../assets/styles/global/settings/variables';

.card {
    display: flex;
    flex-direction: column;
}

.icon {
    position: relative;
    overflow: hidden;
    margin-bottom: 28px;
    border-radius: 8px;
    width: 100%;
    //height: 232px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(0deg, rgba($color-black, 0.24), rgba($color-black, 0.24));
    }

    img {
        width: 100%;
        height: auto;
    }

    @include mobile {
        margin-bottom: 25px;
    }
}

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    background: $color-white;
    transform: translate(-50%, -50%);

    svg {
        width: 32px;
        height: 32px;
        color: $color-accent;
    }

    &:active {
        transform: translate(-50%, -50%) scale(0.9);
    }

    &:hover {
        background: rgba($color-white, 0.9);
    }
}

.title {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: $color-dark;

    @include mobile {
        font-size: 18px;
        line-height: 22px;
    }
}

.description {
    max-width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba($color-dark, 0.8);
}
