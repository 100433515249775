@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/mixins/container";
@import "../../assets/styles/global/settings/variables";
@import "../../assets/styles/global/base/animations";
@import "../../assets/styles/global/settings/colors";

.news_id {
    font-family: $font-family-base;
    font-weight: 700;
    letter-spacing: -0.02em;
}

.hero_container {
    @extend .container;

    font-size: 56px;
    line-height: 0.93em;

    @include mobile {
        grid-template-columns: 100%;
        font-size: 40px;
        line-height: 36px;
    }
}

.back_to_topic {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin: -16px 0 0px;

    a {
        color: $color-dark;
        display: flex;

        &:hover{
            color: $color-accent;
        }
    }

    svg {
        margin-right: 12px;
    }

    @include tablet {
        margin:48px 0px 0;
    }

    @include mobile {
        margin:28px 0px 0;
        font-size: 14px;
    }
}

.title {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 56px;
    color: $color-dark;

    @include mobile {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 32px;
    }
}

.date {
    padding-bottom: 24px;
    font-size: 16px;
    line-height: 1em;
    font-weight: 500;
    border-bottom: 1px solid rgba(9, 41, 156, 0.12);
    color: $color-dark;
}

.label{
    display: block;
    margin-bottom: 12px;
    opacity: 0.48;
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;

    @include mobile {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 11px;
    }
}

.custom_plus {
    &::before, &::after {
        background-color: $color-accent !important;
    }
}

.picture {
    @extend .container;

    height: 560px;
    margin-top: -96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include mobile {
        height: 260px;
        padding: 0;
        margin-top: 0;
    }
}

.main {
    @extend .container;

    margin-top: 60px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include tablet {
        padding-bottom: $tablet_padding_vertical;
        display: block;
        padding-bottom: 0px;
    }

    @include mobile {
        margin-top: 39px;
    }
}

.article {
    width: calc(100% - 560px);

    @include desktop-sm {
        width: calc(100% - 350px);
    }

    @include tablet {
        width: 100%;
        padding-bottom: 32px;
    }

    img{
        height: auto;
        color:#999;
        font-size: 14px;
        line-height: 18px;

        @include mobile {
            height: auto !important;
        }
    } 
}

.aside{
    width: 416px;
    background: #F9F5EE;
    padding: 48px 48px 57px;

    @include desktop-sm {
        width: 300px;
    }

    @include tablet {
        width: 100%;
        padding: 24px 20px 33px;
    }
}

.border {
    @extend .container;

    @include tablet {
        display: none;
    }

    div {
        border-bottom: 1px solid rgba(9, 41, 156, 0.12);
    }
}


.author {
    border-bottom: 1px solid rgba(9, 41, 156, 0.12);
    padding: 24px 0;
}

.author_block{
    display: flex;
    align-items: center;
}

.author_photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 16px;

    > img {
        display: block;
        height: 100%;
    }
}

.author_name {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: $color-dark;

    span{
        display: block;
        margin-top: 8px;
        opacity: 0.64;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
    }
}

.tags {
    padding: 31px 0 18px;
    border-bottom: 1px solid rgba(9, 41, 156, 0.12);
}

.tags_block {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    border: 1px solid rgba(246, 103, 0, 0.4);
    border-radius: 4px;
    color: $color-accent;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    padding: 5px 10px;
    margin: 0 0 6px 6px;

    &:hover{
        background: $color-accent;
        color: $color-white;
    }
}

.social{
    padding-top: 24px;
}

.social_icons {
    display: flex;
    grid-column: span 2;

    @include mobile {
        grid-column: span 1;
    }
}

.social_icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    svg {
        transition: $trans-def;
        fill: $color-dark;
        opacity: .48;
    }

    &:hover{

        svg{
            opacity: 1;
            fill: $color-accent;
        }
    }

}

.content {
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;
    font-weight: 400;
    padding-top: 40px;

    @include mobile {
        padding-top: 40px;
    }

    > h1 {
        margin-bottom: 40px;
        font-size: 40px;
        line-height: 44px;
        color: $color-dark;
        letter-spacing: -0.02em;

        @include mobile {
            margin-bottom: 20px;
            font-size: 28px;
            line-height: 32px;
        }
    }

    > h2 {
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 36px;
        font-weight: 300;
        color: $color-accent;
        letter-spacing: -0.02em;

        @include mobile {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    > h3 {
        margin: 0 0 36px;
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.01em;

        @include mobile {
            margin: 0 0 24px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    > h4 {
        margin: 0 0 30px;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.01em;

        @include mobile {
            font-size: 18px;
            line-height: 22px;
        }
    }

    > h5 {
        margin: 0 0 24px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.01em;

        @include mobile {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    > blockquote{
        margin: 40px 0;
        background: #F9F5EE url(../../assets/images/icons/blockquote.svg) no-repeat top 40px left 32px;
        border-radius: 16px;
        padding: 56px 64px 40px 69px;
        color: #393E41;
        font-size: 20px;
        line-height: 28px;

        @include mobile {
            padding: 50px 20px 20px;
            font-size: 18px;
            line-height: 24px;
            background-position: top 20px left 20px;
        }
    }

    > *:not(:last-of-type) {
        margin-bottom: 24px;

        @include mobile {
            line-height: 24px;
        }
    }

    > ul {
        margin-bottom: 24px;

        li {
            margin-bottom: 16px;
            padding-left: 40px;
            position: relative;

            &:before {
                position: absolute;
                display: block;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #F66700;
                left: 0;
                top: 8px;
            }
        }
    }

    > ol {
        counter-reset: num;
        margin-bottom: 24px;

        li {
            position: relative;
            margin-bottom: 16px;
            padding-left: 40px;

            &:before {
                content: counter(num) '.';
                counter-increment: num;
                display: inline-block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 32px;
                color: $color-accent;
            }
        }
    }

}

.other_news {
    padding: 40px 0 120px;
    font-family: $font-family-base;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: $color-dark;

    @include tablet {
        padding: 48px 0;
    }

    &__container {
        @extend .container;

    }

    &__head{
        display: flex;
        margin-bottom: 32px;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__header {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;

        @include mobile {
            font-size: 24px;
            line-height: 28px;
        }
    }


    &__main {

        article{
            padding: 0;
            border: #F66700;

            > a{
                flex-direction: column !important;
            
                > div{
                    width: 100% !important;
                }

                > div:first-child{
                    margin-bottom: 15px !important;
                }

                > div:last-child{
                    
                    > div:first-child{
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.navigation{
    display: flex;

    button{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 8px;
        border: 1px solid rgba($color: $color-accent, $alpha: 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        transition: $trans-def;

        svg{
            fill: $color-accent;
            transition: $trans-def;
        }

        &:hover{
            border-color: $color-accent;
            background: $color-accent;

            svg{
                fill: $color-light;
            }
        }
    }
}
.next{
    transform: rotate(180deg);
}

