@import "../../assets/styles/mixins/breakpoints";

.container {
    min-height: var(--vh);
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.content {
    padding-top: 128px;
    flex-grow: 1;

    @include tablet {
        padding-top: 80px;
    }

    @include mobile {
        padding-top: 56px;
    }
}

.scroll_is_disabled {
    margin-top: 0;
    max-height: var(--vh);
}
