@import "../.././assets/styles/global/settings/variables";

.wrapper {
    position: relative;
}

.tabs {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid rgba($color-dark, 0.12);
    }
}

.list {
    margin-top: 24px;
}
