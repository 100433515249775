// headings

.heading_lvl_1 {
    font-size: 5.5rem;
    line-height: 1.14em;
    letter-spacing: -0.01em;
}

.heading_lvl_2 {
    font-size: 4rem;
    line-height: 1.2em;
    letter-spacing: -0.01em;
}

.heading_lvl_3 {
    font-size: 3.2rem;
    line-height: 1.25em;
    letter-spacing: -0.01em;
}

.heading_lvl_4 {
    font-size: 2.4rem;
    line-height: 1.33em;
    letter-spacing: -0.01em;
}

.heading_lvl_5 {
    font-size: 1.8rem;
    line-height: 1.44em;
    letter-spacing: -0.01em;
}

.heading_lvl_6 {
    font-size: 1.6rem;
    line-height: 1.5em;
    letter-spacing: -0.01em;
}


.paragraph_lvl_1_lh_1 {
    font-size: 3.2rem;
    line-height: 1.5em;
}

.paragraph_lvl_2_lh_1 {
    font-size: 2.4rem;
    line-height: 1.33em;
}

.paragraph_lvl_3_lh_1 {
    font-size: 2rem;
    line-height: 1.4em;
}

.paragraph_lvl_4_lh_1 {
    font-size: 18px;
    line-height: 28px;
}

.paragraph_lvl_5_lh_1 {
    font-size: 16px;
    line-height: 24px;
}

.paragraph_lvl_6_lh_1 {
    font-size: 14px;
    line-height: 18px;
}

.paragraph_lvl_6_lh_2 {
    font-size: 14px;
    line-height: 14px;
}

// text
.text {
    &_lvl {
        &_1 {
            &_lh {
                &_1 {
                    font-size: 2rem;
                    line-height: 1em;
                }
            }
        }

        &_2 {
            &_lh {
                &_1 {
                    font-size: 1.8rem;
                    line-height: 1em;
                }
            }
        }

        &_3 {
            &_lh {
                &_1 {
                    font-size: 1.6rem;
                    line-height: 1em;
                }
            }
        }

        &_4 {
            &_lh {
                &_1 {
                    font-size: 1.4rem;
                    line-height: 1em;
                }
            }
        }
    }
}

// label
.label {
    &_lvl {
        &_1 {
            &_lh {
                &_1 {
                    font-size: 1.4rem;
                    line-height: 1em;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                }
            }
        }

        &_2 {
            &_lh {
                &_1 {
                    font-size: 1.1rem;
                    line-height: 1em;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                }
            }
        }
    }
}
