@import "../.././../assets/styles/global/settings/variables";
@import "../.././../assets/styles/mixins/container";

.wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    margin-top: 24px;
    padding-right: 32px;

    @include tablet {
        padding: 0 20px 20px 4px;
    }
}

.field {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1;
}

.attach {
    position: relative;
}

.file {
    &:global(.MuiButton-root) {
        padding: 0;
        width: 48px;
        min-width: 48px;
        height: 48px;
        color: $color-accent;
    }

    svg {
        width: 17px;
        height: 18px;
    }
}

.input {
    resize: none;
    outline: none;
    padding: 8px 16px;
    width: 100%;
    height: auto;
    min-height: 48px;
    box-sizing: border-box;
    border: 1px solid rgba($color-dark, 0.12);
    border-radius: 8px 0 0 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    &:placeholder {
        color: rgba($color-dark, 0.48);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.send {
    &:global(.MuiButton-root) {
        border-radius: 0 8px 8px 0;
        padding: 0;
        width: 48px;
        height: 48px;
        color: $color-white;
    }

    svg {
        transform: rotate(90deg);

        path {
            fill: currentColor;
        }
    }
}
