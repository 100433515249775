@import "../.././assets/styles/global/settings/variables";

.wrapper {
    position: relative;
}

.content {
    position: relative;
    width: calc(100% - 304px);
    min-height: 100%;

    @include tablet {
        padding: 0;
        width: 100%;
    }
}

.filters {
    position: relative;
    margin-top: 16px;
    margin-bottom: 48px;
    padding: 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    &__reset {
        &:global(.MuiButton-root) {
            margin-right: -24px;
            padding: 0 24px;
            color: rgba($color-dark, 0.64);
        }

        span {
            white-space: nowrap;
        }

        svg {
            flex: 0 0 auto;
            margin-right: 8px;
        }
    }

    &__control {
        position: relative;
        border-radius: 8px;
        padding: 8px;
        width: 100%;
        height: 56px;
        border: 1px solid rgba($color-dark, 0.18);

        label {
            transform: translate(0, -6px) scale(0.81);
        }
    }

    &__slider {
        position: relative;

        :global(.MuiSlider-root) {
            position: absolute;
            bottom: -10px;
            left: 12px;
            width: calc(100% - 24px);
        }
    }

    &__input {
        position: absolute;
        left: 8px;
        bottom: 10px;
        display: flex;
        align-items: center;
        border: 0;
        width: max-content;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        background-color: transparent;

        input {
            border: 0;
            max-width: 40px;
            background-color: transparent;
        }

        &:last-of-type {
            left: auto;
            right: 8px;
        }
    }

    &__sorting_by {
        :global(.MuiSelect-select) {
            padding: 0;
            border: 0;
            color: rgba($color-dark, 0.64);
        }

        svg {
            top: calc(50% - 4px);
            fill: rgba($color-dark, 0.64);
        }

        fieldset {
            border: none;
        }
    }
}

.list {
    margin-top: 24px;
}

.scroller {
    position: relative;
}

.view {
    padding-right: 32px;
}

.toolbar {
    display: flex;
    align-items: center;
    border-radius: 16px 16px 0 0;
    width: 100%;
    height: 80px;
    padding: 16px 32px;
    background-color: rgba($color-accent, 0.08);
}

.infobar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color-dark, 0.72);

    &__price {
        display: flex;
        align-items: center;
        width: 50%;

        &-sum {
            margin-right: 5px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: $color-accent;
        }
    }

    &__project {
        display: flex;
        align-items: center;
        width: 50%;

        &-link {
            margin-left: 5px;
            color: $color-accent;

            &:hover {
                color: $color-orange;
            }
        }
    }
}

.user {
    display: flex;
    align-items: center;
    width: 50%;

    &__media {
        flex: 0 0 48px;
        margin-right: 12px;
        border-radius: 50%;
        max-width: 48px;

        img {
            object-fit: cover;
        }
    }

    &__status {
        :global(.MuiBadge-badge) {
            border: 1px solid $color-white;
        }

        &_online {
            :global(.MuiBadge-badge) {
                background-color: $color-green;
            }
        }

        &_offline {
            :global(.MuiBadge-badge) {
                background-color: $color-red;
            }
        }
    }

    &__name {
        width: 100%;
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
    }

    &__info {
        width: 100%;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.72);

        span {
            margin-left: 5px;
        }
    }
}

.timer {
    margin-top: 24px;
    padding: 32px 48px;
    border-radius: 16px;
    background-color: rgba($color-accent, 0.12);

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
    }

    &__time {
        margin: 40px auto;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $color-accent;
    }

    &__start {
        margin-bottom: 16px !important;
    }
}

.controls {
    margin-top: 32px;
}

.checkbox {
    margin-bottom: 24px;
}

.sign_up {
    position: relative;
    margin: 24px auto 120px;
    border-radius: 16px;
    padding: 56px 20px;
    text-align: center;
    background: $color-accent;

    &_fill {
        margin-bottom: 48px;
    }

    @include tablet {
        margin: 16px auto;
        padding: 32px 20px;

        &_fill {
            margin-bottom: 48px;
        }
    }

    @include mobile {
        &_fill {
            margin-bottom: 32px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: $color-white;

        span {
            margin-right: 32px;
        }

        @include tablet {
            display: block;
        }

        @include mobile {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__avatars {
        @include tablet {
            margin-top: 16px;
            justify-content: center;
        }
    }

    &__btn {
        min-width: 216px !important;
        margin: 0 auto;

        @include mobile {
            min-width: 100% !important;
            width: 100%;
        }

        &:global(.MuiButton-root) {
            &:hover {
                background: rgba($color-white, 0.9) !important;
            }
        }
    }
}

.popup {
    [class*="popup-container"] {
        padding: 56px 72px 60px;
    }

    &-title {
        margin: 0;
        margin-bottom: 48px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            width: calc(50% - 8px);
        }
    }
}

.help {
    position: relative;
    display: block;
    border: 1px solid rgba($color-accent, 0.24);
    border-radius: 16px;
    padding: 32px 32px 32px 60px;
    text-decoration: none;

    &:hover {
        border-color: $color-accent;
    }

    &__icon {
        position: absolute;
        top: 35px;
        left: 32px;
        width: 16px;
        height: 16px;
    }

    &__title {
        margin-bottom: 8px !important;
        font-size: 18px;
        line-height: 22px;
    }

    &__text {
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.64);
    }
}
