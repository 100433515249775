@import "../../assets/styles/global/settings/variables";


.list {
    margin: 24px auto 56px;
    max-width: 928px;

    @include tablet {
        max-width: 100%;
    }

    @include mobile {
        margin: 12px auto 24px;
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: $color-accent;
    background-color: rgba($color-accent, 0.08);
    transition: $trans-def;

    :global(.Mui-expanded) & {
        color: $color-white;
        background-color: $color-accent;
    }
}
