@import "./breakpoints";

.container {
    margin: 0 auto;
    padding: 0 var(--container-padding-size);
    width: 100%;
    max-width: calc(var(--container-width) + var(--container-padding-size) * 2);
    box-sizing: border-box;
}

.container-lg {
    @extend .container;
    --container-width: 100%;
}
