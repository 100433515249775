@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";


.footer {
    position: relative;
    z-index: 2;
    margin-top: auto;
    padding: 58px 0 40px 0;
    background: $color-accent-reverse;

    @include tablet {
        padding: 48px 0 32px;
    }
}

.footer_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
    }
}

.container {
    @extend .container;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: $color-accent;

    &:hover {
        color: $color-dark;
    }

    @include tablet {
        margin-right: 0;
    }

    @include mobile {
        max-width: 130px;
    }
}

.nav{
    @include tablet {
        width: 100%;
    }
}

.links {
    display: flex;

    @include tablet {
        margin: 24px 0;
        justify-content: space-between;
        width: 100%;
    }

    @include mobile {
        flex-direction: column;
        margin: 26px 0 32px;
    }

    li {
        margin-right: 64px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;

        @include tablet {
            margin: 0;

        }

        @include mobile {
            height: 16px;
            margin-bottom: 24px;
        }

        &:last-child {
            margin-right: 0;

            @include mobile {
                margin-bottom: 0;
            }
        }

        a {
            margin-right: 0;
            transition: .2s all ease;
            color: $color-dark;

            &:hover {
                color: $color-accent;
            }
        }
    }
}

.link {
    font-weight: 500;
    text-decoration: none;

    &:hover {
        opacity: 1;
    }
}

.socials {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.social {
    color: rgba($color-dark, 0.48);
    transition: $trans-def;

    &:not(:last-child) {
        margin-right: 26px;
    }

    &:hover {
        color: $color-accent;

        svg {
            fill: $color-accent;
            opacity: 1;
        }
    }

    svg {
        fill:$color-dark;
        opacity: 0.48;
        transition: $trans-def;
    }
}

.copy_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 56px;
    padding-top: 40px;
    border-top: 1px solid rgba($color-dark, .1);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color-dark, .7);

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 28px;
        padding-top: 16px;
    }
}

.copy {
    margin-right: 113px;

    p {
        display: inline;
    }

    @include tablet {
        margin: 0 0 16px 0;

        span {
            display: inline-block;
            margin: 0 5px;
            font-weight: normal;
            opacity: .3;
        }
    }
}

.copy_links {
    margin-right: auto;


    @include tablet {
        margin: 0 0 16px 0;
    }
}

.copy_link {
    display: inline-block;
    margin-right: 30px;
    color: rgba($color-dark, 0.7);
    transition: $trans-def;

    &:hover {
        color: $color-accent;
    }
}


.developer {
    display: flex;

    a {
        display: inline-block;
        margin-left: 4px;
        font-weight: 700;
        color: $color-dark;
        transition: $trans-def;

        svg {
            fill:$color-dark;
            transition: $trans-def;
        }

        &:hover {
            color: $color-accent;

            svg {
                fill: $color-accent;
            }
        }
    }
}
