@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";


.fullscreen_menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    max-height: var(--vh);
    background-color: $color-accent-reverse;
    z-index: 12;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: .2s ease-in-out;
    overflow-y: auto;
    @extend .container;
}

.header__mobile {
    @include burger {
        display: flex;
    }
    padding: 26px 0;
    height: 72px;
    justify-content: space-between;
    align-items: center;

    .dashboard_style &::before {
        content: '';
        position: absolute;
        height: inherit;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $color-dark;
    }

    @include mobile {
        height: 64px;
        padding: 20px 0;
    }
}

.nav {
    padding: 10px 0 24px;
    margin: 0 auto;
    width: 100%;
    max-width: calc(var(--container-width) + var(--container-padding-size) * 2);
    height: 100%;
}

.burger {
    color: $color-accent;
}

.body {
    position: relative;
    max-width: 100%;
    padding: 65px 16px 0 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.pages {
    margin-bottom: auto;

    @include mobile-landscape {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        margin-bottom: 32px;
        color: $color-accent;

        @include mobile-landscape {
            margin-right: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.active_link {
            display: flex;
            align-items: center;
            color: $color-accent;

            &:after {
                content: '';
                margin-left: 24px;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-accent;
            }
        }
    }
}

.logo {
    color: $color-accent;
    transition: $trans-def;
    display:flex;
    z-index: 1;

    path {
        transition: $trans-def;
    }

    .dashboard_page &, .dashboard_style & {
        path:not([fill="currentColor"]) {
            fill: white;
        }

        &:hover {
            path[fill="currentColor"] {
                fill: white;
            }
        }
    }

    &:hover {
        color: $color-dark;
    }
}

.page {
    display: block;
    font-size: 2.4rem;
    line-height: 1em;
    letter-spacing: -0.025em;
    color: inherit;
}

.is_active {
    visibility: visible;
    opacity: 1;
}

.footer {
    position: fixed;
    bottom: 48px;
    width: calc(100% - 32px);
}

.is_hidden {
    visibility: hidden;
    opacity: 0;
}

.buttons {
    width: 100%;
    padding: 0 0px 50px;

    @include mobile-landscape {
        //display: flex;
    }

    @include mobile {
        padding-bottom: 24px;
    }

    & a {
        display: block;

        & button {
            width: 100%;
            height: 56px;
            padding: 20px 0;
        }
    }

}

.sign {
    &_up {
        margin-bottom: 12px;

        & button {
            border: 1px solid rgba($color-accent, 0.24);
        }
    }
}

.btn {
    button {
        max-width: 100%;
        width: 100%;
        display: none;
        margin-top: 30px;

        @include mobile {
            display: block;
        }
    }

    @include mobile-landscape {
        width: 20%;
    }

    &:first-child {
        margin-bottom: 8px;
        display: block;
        bottom: 48px;
        width: 100%;
        z-index: 10;

        @include mobile-landscape {
            margin-bottom: 0;
            margin-right: 8px;
        }
    }
}

@keyframes animation_search {
    from {
        max-width: 150px;
    }

    to {
        max-width: 752px;
    }
}

.search {
    position: relative;
    display: flex;
    align-items: center;
    margin: 24px 0 8px;

    &__input {
        width: 100%;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 13px 16px;
        padding-right: 150px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        background: $color-white;
        box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);
    }

    &__submit {
        position: absolute;
        top: 0;
        right: 112px;
        padding:15px 11px;
        border: none;
        color: $color-accent;
        background: none;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            right: -8px;
            top: 50%;
            width: 1px;
            height: 24px;
            background-color: rgba($color-dark, 0.12);
            transform: translateY(-50%);
            pointer-events: none;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            color: $color-dark;
        }
    }

    &__type {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 16px 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        color: $color-dark;

        svg {
            transform: rotate(-90deg);
        }

        &_menu {
            svg {
                margin-right: 12px;
                transform: rotate(0deg);
            }
        }

        &_active {
            color: $color-accent;
        }
    }
}

.back_to_dashboard {
    transition: $trans-def;
    margin-top: 8px;
    margin-bottom: 35px;
    width: min-content;
    display: flex;
    align-items: center;
    color: $color-dark;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    &:active {
        color: $color-accent!important;
    }

    svg {
        margin-right: 12px;
    }
}

.go_back_svg {
    height: 28px;

    path {
        fill: $color-accent  !important;
    }
}
