@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";

.error {
    @extend .container;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include tablet {
        padding-top: 24px;
    }

    &__title {
        margin: 16px 0 36px !important;
        color: $color-dark;

        @include mobile {
            margin: 8px 0 24px !important;
        }
    }

    &__picture {
        margin-top: 24px;
        margin-bottom: 56px;
        margin-left: -106px;
        width: 704px;
        height: 378px;
        color: transparent;

        @include mobile {
            margin-top: 8px;
            margin-bottom: 48px;
            margin-left: 44px;
            width: 554px;
            height: 298px;
        }
    }

    &__button {
        margin-top: 48px;

        @include mobile {
            margin-top: 32px;
            width: 100%;
        }
    }
}
