@import '../../assets/styles/global/settings/variables';
@import '../../assets/styles/extends/section';
@import "../../assets/styles/mixins/container";

.swiper_pagination {
    position: absolute;
    bottom: 34px !important;
    right: calc(var(--swiper-pagination-bullet-horizontal-gap) * -1);
    left: auto !important;
    z-index: 10;
    width: auto !important;

    @include tablet {
        position: static;
        margin-top: 42px;
        text-align: center;
    }

    @include mobile {
        margin-top: 20px;
    }
}

.hero_inner {
    position: relative;
    z-index: 1;
    padding-top: 24px;

    &::before {
        content: '';
        position: absolute;
        top: -162px;
        left: -140px;
        z-index: -1;
        width: 1152px;
        height: 900px;
        background: #faf9f7;

        @include tablet {
            content: none;
        }
    }
}

.hero {
    @extend .container;
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-areas:
        'search_block featured_employee'
        'scroll_down featured_employee';
    grid-template-columns: 35% 60%;
    justify-content: space-between;
    row-gap: 35px;
    padding-bottom: 90px;

    &::before {
        content: '';
        position: absolute;
        top: -162px;
        left: -50vw;
        z-index: -1;
        border-bottom-right-radius: 40px;
        width: calc(1264px + 50vw);
        height: 900px;
        background: #faf9f7;

        @include tablet {
            height: auto;
            bottom: 0;
        }
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        row-gap: 0;
        padding-bottom: 48px;
    }
}

.search_block {
    grid-area: search_block;
    padding-top: 64px;

    @include tablet {
        padding-top: 0;
    }

    &__select {
        width: 100%;

        svg {
            margin-top: 5px;

            @include mobile {
                margin-top: 8px;
                transform: scale(0.7);
            }
        }
    }

    &__form {
        max-width: 90%;

        @include tablet {
            max-width: 100%;
        }
    }

    &__field {
        margin-bottom: 16px;

        &:nth-child(1) {
            svg {
                margin-left: 2px !important;
            }
        }

        > div {
            background: $color-white;
            border: none;
            box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
            border-radius: 8px;
        }
    }

    &__title {
        @extend .heading_lvl_1;
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 56px;
        line-height: 60px;
        letter-spacing: -0.01em;
        color: $color-dark;

        span {
            color: $color-accent;
        }

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 24px;
            font-size: 32px;
            line-height: 36px;
        }
    }

    &__label {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-accent;

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 12px;
        }
    }

    &__text {
        margin-bottom: 48px;
        max-width: 416px;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba($color-dark, 0.8);

        @include tablet {
            margin-right: auto;
            margin-left: auto;
            text-align: center;
        }

        @include mobile {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
        }
    }

    &__link {
        display: inline-block;

        @include tablet {
            margin-bottom: 32px;
            width: 100%;
            text-align: center;
        }
    }

    &__button {
        max-width: 416px;
    }

    &__btn {
        margin-top: 24px;

        @include mobile {
            margin-top: 24px;
        }
    }
}

.featured {
    position: relative;
    margin-left: auto;
    max-width: 752px;
    height: 528px;
    overflow: hidden;
    border-radius: 0 40px 0 40px;

    @include tablet {
        width: 100%;
        height: auto;
    }

    @include mobile {
        height: auto;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0 40px 0 40px;
        background: linear-gradient(0deg, rgba(246, 103, 0, 0.12), rgba(246, 103, 0, 0.12)),
            linear-gradient(111.4deg, rgba(255, 189, 54, 0.72) 7.95%, rgba(243, 115, 53, 0.72) 92.75%),
            linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    }

    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 7;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        background: $color-white;
        transform: translate(-50%, -50%);

        svg {
            width: 32px;
            height: 32px;
            color: $color-accent;
        }

        &:active {
            transform: translate(-50%, -50%) scale(0.9);
        }

        &:hover {
            background: rgba($color-white, 0.9);
        }
    }
}

.videoPopup {
    :global .popup-container {
        position: relative;
        margin: auto;
        padding: 70px 72px 54px;
        max-width: 1117px;
        min-width: 1117px;
        border-radius: 16px;

        @include desktop {
            min-width: calc(100vw - 64px);
            width: 100%;
            max-width: 980px;
        }

        @include tablet {
            min-width: calc(100vw - 64px);
            width: 100%;
            max-width: 680px;
        }

        @include mobile {
            padding: 24px;
            height: auto;
            min-width: 100%;
            max-width: 100%;
        }
    }
}

.featured_video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include tablet {
        margin-bottom: -7px;
    }

    @include mobile {
        padding-top: 23px;
    }
}

.featured_video_mob {
    display: none;
    width: 100%;
    height: auto;
    border-radius: 0 60px 0 60px;

    @include tablet {
        display: block;
        border-radius: 0 40px 0 40px;
    }
}

.scroll_down {
    display: flex;
    align-items: center;

    @include tablet {
        display: none;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 83px;
        margin-right: 24px;
        background: $color-white;
        box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
        border-radius: 100px;
        cursor: pointer;
        transition: $trans-def;

        svg {
            fill: $color-dark;
            transition: $trans-def;
        }

        &:hover {
            color: $color-white;
            background-color: $color-orange;

            svg {
                fill: $color-white;
            }
        }
    }

    span {
        display: block;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $color-dark;
    }

    p {
        max-width: 198px;
        font-size: 14px;
        line-height: 18px;
        color: rgba($color: $color-dark, $alpha: 0.64);
    }
}

// about
.about {
    @extend .container;
    position: relative;
    padding-top: 105px;
    padding-bottom: 96px;

    @include tablet {
        padding-top: 48px;
        padding-bottom: 48px;
        background: $color-accent-reverse;
    }

    @include mobile {
        padding-top: 32px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -90px;
        right: -50vw;
        z-index: -2;
        width: 50vw;
        height: 784px;
        background: $color-accent-reverse;

        @include tablet {
            content: none;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-left: 176px;

        &::before {
            content: '';
            position: absolute;
            top: -195px;
            left: 112px;
            z-index: -2;
            border-bottom-left-radius: 40px;
            width: 1264px;
            height: 830px;
            background: $color-accent-reverse;

            @include tablet {
                content: none;
            }
        }

        @include tablet {
            display: block;
            padding-left: 0;
        }
    }

    &__text {
        width: 40%;

        @include tablet {
            width: 100%;
        }
    }

    &__title {
        @extend .section_title;

        @include mobile {
            margin-bottom: 24px;
            text-align: center;
        }
    }

    &__subtitle {
        @extend .section_subtitle;

        margin: 16px 0;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-accent;

        @include tablet {
            margin-bottom: 8px;
            text-align: center;
        }

        @include mobile {
            font-size: 12px;
            line-height: 12px;
        }
    }

    &__desc {
        @extend .section_desc;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 24px;

        @include tablet {
            margin-bottom: 24px;
        }
    }

    &__privileges {
        margin-bottom: 48px;

        @include tablet {
            margin-bottom: 32px;
        }
    }

    &__privilege {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        flex-grow: 0;

        svg {
            min-width: 20px;
            min-height: 20px;
            color: transparent;

            path {
                stroke: $color-accent;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__priv_text {
        margin: 0 0 0 15px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: $color-dark;

        @include mobile {
            margin-left: 12px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__achievements {
        margin-left: auto;
        width: 50%;

        @include tablet {
            width: 100%;
            margin-top: 32px;
        }
    }

    &__buttons {
        display: flex;
        gap: 16px;

        a {
            color: $color-white;
        }

        @include tablet {
            justify-content: center;
        }

        @include mobile {
            flex-direction: column;
        }
    }

    &__btn {
        width: 100% !important;
        max-width: 206px !important;
        white-space: nowrap;

        @include mobile {
            max-width: 100% !important;
        }
    }

    &__row {
        width: 100%;
        display: flex;

        @include mobile {
            border: none !important;
        }

        &:first-child {
            border-bottom: 1px solid rgba($color: $color-dark, $alpha: 0.12);

            .about__achievement {
                padding: 10px 0 40px 0;

                @include mobile {
                    padding: 0 0 24px 0;
                }
            }
        }

        &:nth-child(2n) {
            .about__achievement {
                padding: 40px 0 10px 0;

                @include mobile {
                    padding: 0;
                }
            }
        }
    }

    &__achievement {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:first-child {
            border-right: 1px solid rgba($color: $color-dark, $alpha: 0.12);
        }

        @include mobile {
            border: none !important;
        }
    }

    &__circle {
        margin-bottom: 28px;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
        background-color: $color-white;
        color: $color-accent;

        @include tablet {
            margin-bottom: 16px;
        }

        @include mobile {
            margin-bottom: 12px;
            width: 130px;
            height: 130px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__label {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $color-dark;

        @include mobile {
            font-size: 14px;
            line-height: 14px;
            text-align: center;
        }
    }
}

/*.features {
    @extend .container;
    padding: 120px 0;

    @include tablet {
        padding: 48px 0;
    }
    @include mobile {
        padding: 48px 0 0 0;
    }
    &__header {
        text-align: center;
        flex-direction: column
    }
    &__subtitle {
        @extend .section_subtitle;
    }
    &__title {
        @extend .section_title;
        @include mobile{
            margin-bottom: 14px!important;
        }
        span{
            color: #005EFF;
        }
    }
    &__cards {
        display: flex;
        justify-content: space-between;
        @include tablet{
            padding: 0 20px;
        }
        @include mobile{
            padding: 0;
        }
        @include mobile{
            display: none;
        }
    }
    &__cards_mobile{
        //display: none;
        @include mobile{
            display: block;
        }
    }
    &__card {
        //width: 22%;
        @include mobile{
            width: calc(100vw - 40px);
            margin: 0 20px;
        }
    }
    &__slide {
        height: auto;
        margin: 10px 0 88px 0;
    }
}*/


.features {
    @extend .container;
    padding: 124px 0 88px;

    @include tablet {
        padding: 48px 0;
    }

    @include mobile {
        padding: 48px 0 56px 0;
    }

    &__header {
        text-align: center;
        flex-direction: column;
    }

    &__subtitle {
        @extend .section_subtitle;

        @include mobile {
            margin-bottom: 8px;
        }
    }

    &__title {
        @extend .section_title;
        margin-bottom: 32px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &__cards {
        @extend .container;
        position: relative;

        &_slider {
            margin: 0 -24px;
            padding: 16px 24px 32px;
        }

        @include tablet {
            padding: 0 20px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &__pagination {
        bottom: -14px !important;
    }

    &__cards_mobile {
        display: none;

        @include mobile {
            display: block;
        }
    }

    &__card {
        @include mobile {
            width: calc(100vw - 40px);
            margin: 0 20px;
        }
    }

    &__slide {
        margin: 10px 0 88px 0;
    }
}

.questions {
    padding: 120px 0;
    border: none !important;

    @include tablet {
        padding: 48px 0;
    }

    &__header {
        text-align: center;
    }

    &__title {
        @extend .section_title;

        margin: 0 24px 24px;

        @include mobile {
            margin-bottom: 0px;
        }
    }

    &__subtitle {
        @extend .section_subtitle;
        text-transform: uppercase;

        @include mobile {
            margin-bottom: 8px;
        }
    }

    &__all {
        min-width: 185px !important;
        margin: 0 auto 120px auto;

        @include tablet {
            margin: 32px auto 62px auto;
        }

        @include mobile {
            max-width: calc(100% - 40px) !important;
            min-width: auto !important;
            width: 100% !important;
            margin: 32px auto 62px auto;
        }
    }

    &__footer {
        margin: 0 auto;
        text-align: center;
    }
}

.experts {
    padding: 95px 0;
    background-color: #fAf9f7;

    @include tablet {
        padding: 48px 0 48px 0;
    }

    @include mobile {
        padding: 48px 0 0 0;
    }

    &__container {
        @extend .container;
    }

    &__header {
        margin-bottom: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include tablet {
            margin-bottom: 24px;
        }

        @include mobile {
            margin-bottom: 3px;
        }
    }

    &__slider {
        position: relative;
    }

    &__pagination {
        bottom: 42px !important;
    }

    &__subtitle {
        @extend .section_subtitle;

        @include mobile {
            margin-bottom: 8px;
        }
    }

    &__title {
        @extend .section_title;

        @include mobile {
            margin-bottom: 24px;
        }
    }

    &__desc {
        @extend .section_desc;
        max-width: 420px;
        color: $color-dark;

        @include mobile {
            max-width: 100%;
        }
    }

    &__btn {
        margin: 0 auto;

        @include mobile {
            display: none !important;
        }
    }

    &__swiper {
        padding: 30px 0 !important;

        @include desktop-sm {
            margin: 0 20px !important;
            padding: 20px 0 100px 0 !important;
        }

        @include mobile {
            padding: 20px 0 88px 0 !important;
        }
    }

    &__slide {
        transition: all 0.3s ease;

        &:hover {
            transform: translateY(-30px);

            @include desktop-sm {
                transform: translateY(0px);
            }
        }

        &:nth-child(odd) {
            //transform: translateY(-30px);

            @include tablet {
                transform: translateY(0);
            }
        }

        button {
            max-width: 100%;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        max-width: 310px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $color-dark;

        &_text {
            margin-bottom: 24px;

            @include mobile {
                display: none;
            }
        }
    }
}

.partners {
    position: relative;
    z-index: 2;
    padding: 120px 0 104px;

    @include tablet {
        padding: 80px 0;
        background: #faf9f7;
    }

    @include mobile {
        padding: 48px 0 86px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -140px;
        z-index: -1;
        width: 1152px;
        bottom: 0;
        background: #faf9f7;

        @include tablet {
            content: none;
        }
    }

    &__wrapper {
        @extend .container;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 232px;
            z-index: -1;
            border-bottom-right-radius: 40px;
            width: 1152px;
            bottom: 0px;
            background: #faf9f7;

            @include tablet {
                content: none;
            }
        }
    }

    &__title {
        @extend .section_title;

        margin-bottom: 54px;
        text-align: center;

        @include mobile {
            margin-bottom: 24px;

            span {
                display: block;
            }
        }
    }

    &__pagination {
        bottom: 40px !important;

        @media (min-width: (1025px)) {
            display: none;
        }
    }

    &__subtitle {
        @extend .section_subtitle;

        text-align: center;

        @include tablet {
            margin-top: 0;
            margin-bottom: 8px;
            text-align: center;
        }

        @include mobile {}
    }
}

.reviews {
    position: relative;
    z-index: 1;
    padding: 245px 20px 48px 20px;
    background-color: $color-accent-reverse;

    &:last-child {
        @include tablet {
            padding-bottom: 24px;
        }
    }

    @include tablet {
        padding: 48px 20px 0 20px;
    }

    &__container {
        position: relative;
        max-width: 864px;
        margin: 0 auto;

        &::before {
            content: '';
            position: absolute;
            top: -52px;
            left: -224px;
            width: 408px;
            height: 325px;
            background: url('../../../public/images/quote.svg') no-repeat;

            @include tablet {
                width: 68px;
                height: 54px;
                top: -29px;
                left: -10px;
                background: url('../../../public/images/quote_mobile.png') no-repeat;
            }
        }
    }
}

.find_expert {
    position: relative;
    max-width: 1088px;
    margin: 120px auto 0 auto;
    padding: 56px 0;
    background: $color-accent;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    text-align: center;

    @include tablet {
        margin: 48px auto 0 auto;
        padding: 32px 20px;
    }

    @include mobile {
        margin: 40px auto 0 auto;
        padding: 40px 20px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 245px;
        height: 324px;
        background: url('../../../public/images/find_expert_bg_left.png') no-repeat;
        border-bottom-left-radius: 40px;
        pointer-events: none;

        @include tablet {
            height: 100%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 268px;
        height: 324px;
        background: url('../../../public/images/find_expert_bg_right.png') no-repeat;
        border-top-right-radius: 40px;
        pointer-events: none;

        @include tablet {
            height: 100%;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -0.01em;
        color: $color-white;

        @include mobile {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__desc {
        max-width: 570px;
        margin: 24px auto 32px;
        font-size: 18px;
        line-height: 28px;
        color: $color-white;

        @include mobile {
            margin: 20px auto 32px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__btn {
        min-width: 216px !important;
        margin: 0 auto;

        @include mobile {
            min-width: 100% !important;
            width: 100%;
        }

        &:global(.MuiButton-root) {
            &:hover {
                background: rgba($color-white, 0.9) !important;
            }
        }
    }
}

//Accordion
.accordion_panel {
    margin-bottom: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba($color-dark, 0.12);
}
