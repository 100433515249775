@import '../../assets/styles/global/settings/variables';

.container {
    padding: 32px;
    padding-top: 56px;
    height: 100%;
    box-shadow: 0 12px 24px rgba(0, 20, 91, 0.06), 0 0 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 16px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s ease;

    @include mobile {
        padding: 32px 16px;
        border-radius: 12px;
    }
}

.icon {
    margin-bottom: 32px;

    @include mobile {
        margin-bottom: 24px;
    }

    img{
        @include mobile {
            width: 60px;
            height: auto;
            border-radius: 8px;
        }
    }
}

.title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;

    &:not(:last-child) {
        margin-bottom: 16px;

        @include mobile {
            margin-bottom: 12px;
        }
    }

    @include mobile {
        font-size: 18px;
        line-height: 18px;
    }
}

.description {
    max-width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @include mobile {
        max-width: 100%;
    }
}

.color {
    &_light {
        background: $color-light;

        .title {
            color: $color-dark;
        }

        .description {
            color: $color-dark;
            opacity: 0.64;
        }
    }

    &_accent {
        background-color: $color-accent-reverse;

        .title {
            color: $color-light;
        }

        .description {
            color: $color-light;
        }
    }
}
