@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/global/settings/variables";

.back {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;
    max-width: 120px;

    svg {
        display: inline-block;
        margin-right: 12px;

        path {
            transition: $trans-def;
        }
    }

    &:hover {
        color: $color-accent;

        path {
            stroke: $color-accent;
        }
    }

    @include mobile {
        font-size: 14px;
        line-height: 14px;
    }
}

.layout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    &:not(:last-child) {
        margin-bottom: 32px;

        @include mobile {
            margin-bottom: 16px;
        }
    }
}

.history {
    padding: 32px;
    flex-wrap: wrap;
    justify-content: initial;
    align-items: initial;
    flex-direction: column;

    @include mobile {
        padding: 16px;
        margin-bottom: 24px !important;
    }
}

.confirm {
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
    }
}

.confirm_info {
    text-align: initial;

    @include mobile {
        margin-bottom: 16px;
    }
}

.session_summary_title {
    margin-bottom: 10px;
}

.session_summary_item {
    margin-top: 10px;
    font-weight: 400;
    line-height: 16px;

    > span {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: $color-orange;
    }
}

.confirm_buttons {
    display: flex;
    flex-direction: column;

    button:first-child {
        margin-bottom: 16px;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($color-dark, 0.12);

        @include mobile {
            margin-bottom: 12px;
            padding-bottom: 12px;
        }
    }
}

.row_text {
    margin-right: 10px;
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
    color: $color-dark;

    @include mobile {
        font-size: 14px;
    }
}

.row_main {
    display: flex;
    align-items: center;

    :global(.MuiAvatar-root) {
        margin-right: 10px;

        @include mobile {
            width: 32px;
            height: 32px;
        }
    }
}

.row_desc {
    text-align: left;
}

.row_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 1em;
    color: rgba($color-dark, 0.64);
    text-align: right;

    @include mobile {
        font-size: 13px;
    }
}

.icon {
    position: relative;
    margin: 8px 16px 8px 8px;

    height: 18px;

    &::before {
        content: '';
        top: 50%;
        left: 50%;
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: rgba($color-accent, 0.08);
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    &.icon_middle {
        height: 20px;
    }

    &.icon_green {
        height: 24px;

        &::before {
            background-color: rgba(#1CAD70, 0.08);
        }
    }

}

.wrapper {
    position: relative;
}

.tabs {
    position: relative;
    margin: -20px 0 24px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid rgba($color-dark, 0.12);
    }
}

.title {
    margin-top: 48px !important;
    margin-bottom: 24px !important;

    a {
        margin-left: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $color-accent;

        &:hover {
            color: $color-orange;
        }
    }
}

.name {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
}

.date {
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba($color-dark, 0.12);
}

.main {
    &__content {
        flex: 1;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba($color-dark, 0.12);
    }

    &__right {
        flex: 0 0 278px;
        padding-top: 116px;
        max-width: 278px;
    }

    &__information {
        display: flex;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 32px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 4px;
        }
    }

    &__category {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba($color-dark, 0.12);

        &_item {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: $color-dark;

            &:not(:last-child) {
                margin-right: 32px;
            }

            @include tablet {
                margin-top: 16px;
            }
        }

        @include tablet {
            display: block;
        }
    }

    &__files {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 40px;
    }

    &__skills {
        &:not(:last-child) {
            margin-bottom: 24px;

            @include mobile {
                margin-bottom: 12px;
            }
        }

        &_title {
            margin-bottom: 24px !important;
        }

        &_label {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: rgba($color: $color-dark, $alpha: 0.8);

            @include mobile {
                font-size: 12px;
                line-height: 13px;
            }
        }

        &_desk {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-dark;
        }

        &_list {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            li {
                position: relative;
                display: block;
                padding-left: 12px;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    margin-top: 10px;
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    background-color: $color-accent;
                }
            }

            &_special {
                li {
                    &:not(:last-child) {
                        margin-bottom: 24px;
                    }
                }

                :global(.text) {
                    margin-bottom: 24px;
                }
            }
        }

        & > div {
            flex-wrap: wrap;

            div {
                margin: 0 8px 8px 0 !important;
            }
        }
    }

    &__services {
        align-self: center;
        margin-bottom: 40px;
    }
}

.job,
.location {
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color: $color-dark, $alpha: 0.72);

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }

    @include mobile {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.firstText {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $color-accent;

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }
}

.desc {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;

    @include mobile {
        margin-bottom: 16px;
    }
}

.file {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 32px;
    color: $color-dark;

    &__content {
        margin-left: 12px;
    }

    &__media {
        width: 32px;
        height: 32px;

        img {
            object-fit: cover;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    &__size {
        font-weight: 500;
        margin-top: 2px;
        font-size: 12px;
        line-height: 12px;
        opacity: 0.7;
    }

    &:hover {
        color: $color-accent;
    }
}

.step {
    margin-bottom: 40px;

    &__item {
        &:last-of-type {
            .step__icon::before {
                display: none;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        :global(.MuiStepLabel-labelContainer) {
            max-width: calc(100% - 16px);
        }

        :global(.MuiStepLabel-label) {
            display: flex;
            line-height: 0.75;

            @include mobile {
                flex-direction: column;
            }
        }

        // &_year {
        //     :global(.MuiStepLabel-label) {
        //         flex-direction: row;
        //     }
        // }

        // &_certificate {
        //     &:not(:last-of-type) {
        //         margin-bottom: 32px;
        //     }
        // }
    }

    &__divider {
        display: inline-block;
        margin: 0 8px;
        font-size: 24px;
        line-height: 24px;
    }

    &__label {
        &:global(.MuiStepLabel-root) {
            align-items: flex-start;
            padding: 0;
            line-height: 0.75;
        }
    }

    &__title {
        flex: 0 0 auto;
        margin-right: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        b {
            margin-right: 8px;
            font-weight: 500;
        }
    }

    // &__icon {
    //     position: relative;
    //     margin-top: 7px;
    //     margin-right: 8px;
    //     border-radius: 50%;
    //     width: 8px;
    //     height: 8px;
    //     background: rgba($color-dark, 0.12);

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         left: 3px;
    //         top: 100%;
    //         width: 1px;
    //         height: 20px;
    //         background-color: rgba($color-dark, 0.12);
    //     }

    //     &_active {
    //         background-color: $color-accent;
    //     }
    // }

    &__iconDot {
        margin-top: 7px;
        margin-right: 8px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: rgba($color-dark, 0.12);

        &_active {
            background-color: $color-accent;
        }
    }

    &__connector {
        flex: 1 0 auto;
        margin-left: 3px;
    }

    &__connectorDot {
        flex: 1 0 auto;
        margin-left: 3px;
    }

    &__line {
        margin: -20px 0 -12px;
        width: 1px;
        height: 100%;
        min-height: 22px;
        background: rgba($color-dark, 0.12);
    }
}

.reviews {
    position: relative;
    margin-top: 16px;
    margin-bottom: 64px;
    padding: 28px 32px 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    @include tablet {
        width: 100%;
    }

    &__title {
        margin-bottom: 32px;

        span {
            margin-left: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.64);
        }
    }
}

.review {
    margin-bottom: 32px;
    max-width: 602px;

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &__avatar {
        margin-right: 12px;
    }

    &__name {
        margin-right: 12px;
    }

    &__stars {
        display: flex;
        align-items: center;
    }

    &__date {
        margin-left: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.64);
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba($color-dark, 0.8);
    }
}


.info {
    display: flex;
    align-items: center;
    margin: 16px 0;

    @include tablet {
        display: block;
    }

    &__price {
        margin-right: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: $color-accent;

        @include tablet {
            margin-right: 0;
            margin-bottom: 8px;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.72);
        }
    }

    &__type {
        display: flex;
        align-items: center;
        margin-right: 32px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #3e7eea;

        @include tablet {
            margin-right: 0;
            margin-bottom: 8px;
        }

        svg {
            margin-right: 8px;

            path {
                stroke: currentColor;
            }
        }

        &_session {
            color: #1cad70;
        }
    }

    &__profession {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $color-accent;
    }

    &__status {
        margin-left: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: right;
        color: rgba($color-dark, 0.64);

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        b {
            font-weight: 700;
            color: $color-accent;
        }
    }
}

.button {
    width: fit-content;
    align-self: center;

    @include mobile {
        width: 100%;
    }
}

.status {
    font-size: 16px;

    .row {
        justify-content: initial;
    }
}

.leave_revew_line {
    margin-bottom: 20px;
}

.rate_title {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 1em;

    &_alert {
        color: $color-red;
    }
}

.stars {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
}

.star {
    transition: $trans-fast;
    cursor: pointer;

    path {
        fill: rgba($color-dark, 0.16);
    }

    &_active {
        path {
            fill: #FEAB2F;
        }
    }

    &_selecting {
        path {
            fill: $color-orange;
        }
    }
}

.rate_alert {
    transition: $trans-fast;
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: -20px;
    color: $color-red;
    font-size: 13px;

    &_active {
        opacity: 1;
    }
}

.skeletonTop {
    display: flex;
    justify-content: space-between;
    width: 240px;
}

.skeletonBody {
    margin-top: 24px;
    padding: 24px;
    height: 50%;
    border-radius: 8px;
    background-color: white;
}
