@import './typography.scss';
@import '../mixins/breakpoints';

.section_title {
    margin: 0;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.01em;
    color: $color-dark;

    span {
        color: $color-accent;
    }

    @include tablet {
        font-size: 36px;
        line-height: 40px;
    }

    @include mobile {
        font-size: 28px;
        line-height: 32px;
    }
}

.section_subtitle {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $color-accent;

    @include mobile {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 12px;
    }
}

.section_desc {
    @extend .paragraph_lvl_5_lh_1;
    color: rgba($color: $color-black, $alpha: 0.8);
}
