@import '../../assets/styles/global/settings/variables';


.text {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: $color-dark;

    @include tablet {
        font-size: 26px;
        line-height: 32px;
    }

    @include mobile {
        font-size: 20px;
        line-height: 28px;
    }
}

.footer {
    display: flex;
    align-items: center;
    margin-top: 48px;

    @include tablet {
        margin-top: 36px;
    }

    &__info {
        margin-left: 32px;

        @include mobile {
            margin-left: 16px;
        }
    }
}

.img {
    img {
        border-radius: 28px;
        object-fit: cover;
    }

    @include mobile {
        position: absolute;
        display: none;
    }
}

.img_mobile {
    display: none;

    img {
        border-radius: 28px;
        object-fit: cover;
    }

    @include mobile {
        display: block;
    }
}

.name {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: $color-dark;

    @include mobile {
        font-size: 16px;
        line-height: 16px;
    }
}

.position {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    color: rgba($color-dark, 0.64);

    @include mobile {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
    }
}
