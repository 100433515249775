@import '../../../assets/styles/global/settings/variables';


.title {
    a {
        margin-left: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $color-accent;

        &:hover {
            color: $color-orange;
        }
    }
}

.top_card {
    display: flex;
    padding: 24px 32px;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    @include desktop {
        padding: 16px;
        flex-direction: column;
    }
}

.my_data {
    display: grid;
    grid-template-columns: 80px auto 18px;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 24px;

    @include desktop {
        width: 100%;
        grid-template-columns: 60px 2fr 18px;
        grid-column-gap: 8px;
    }
}

.edit {
    display: flex;
    align-items: center;
    margin-left: -8px;

    svg {
        transition: $trans-def;
    }

    &:hover {
        svg {
            transform: scale(1.08);
        }

        path {
            stroke: $color-orange;
        }
    }
}

.navigate_buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    a {
        color: $color-white;
        white-space: nowrap;

        &:hover {
            text-decoration: none;
        }
    }

    @include desktop {
        width: 100%;
    }

    @include mobile {
        flex-direction: column;

        a {
            width: 100%;
        }
    }
}

.my_avatar {
    width: 80px !important;
    height: 80px !important;

    @include desktop {
        width: 60px !important;
        height: 60px !important;
    }
}
