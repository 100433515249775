@import '../../assets/styles/global/settings/variables';

.header {
    &::after {
        content: "";
        position: absolute;
        left: -57px;
        z-index: -1;
        width: 50vw;
        height: 100%;
        background-color: $color-light;

        @include mobile {
            left: 0;
            width: 100%;
        }
    }
}

.auth {
    overflow-x: hidden;
}

.main {
    min-height: 100vh;
    min-height: var(--vh);
    display: flex;
}

.auth_container {
    background-color: $color-bg;

    > main {
        padding-top: var(--container-padding-size);
        padding-bottom: 24px;

        @include mobile {
            padding-top: 52px;
        }
    }

    @include mobile {
        padding: 0 var(--container-padding-size);
    }
}

.auth_form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &_social {
        max-width: 480px;
    }
}

.auth_box {
    margin: 48px auto auto;
    padding: 56px 72px;
    max-width: 560px;
    width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: white;

    @include mobile {
        margin-top: 32px;
        padding: 20px 16px;
        border-radius: 12px;
    }
}

.form_container {
    width: 100%;
}

.social_label {
    width: 100%;
    margin-bottom: -8px;
}

.signup_button {
    width: 100%;

    svg {
        position: absolute;
        left: 24px;

        @include mobile {
            left: 16px;
        }
    }
}

.signup_button_mail {
    &:hover {
        svg path {
            fill: $color-light;
        }
    }
}

.back {
    box-sizing: initial;
    height: 24px;
    margin-bottom: 16px;
    padding: 4px 4px 4px 0;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    color: $color-dark;
    cursor: pointer;
    transition: $trans-def;

    svg {
        margin-right: 10px;

        path {
            stroke: $color-dark;
            transition: $trans-def;
        }
    }

    &:hover {
        color: $color-accent;

        svg path {
            stroke: $color-accent;
        }
    }
}

.forgot_link {
    color: $color-accent;
    transition: $trans-def;
}

.button_eye {
    color: $color-accent;

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: $color-accent;
        }
    }
}

.media {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 55px;

    &::before {
        content: '';
        position: absolute;
        top: calc(-1 * var(--container-padding-size));
        right: -100vw;
        bottom: -24px;
        left: 0;
        z-index: -1;
        background-color: $color-accent;
    }

    @include tablet {
        display: none;
    }
}

.media__picture {
    margin: auto 0;
    margin-left: var(--container-padding-size);

    img {
        @media (min-width: 1600px) {
            width: 672px;
        }

        @include desktop-sm {
            width: 500px;
        }

        @media (max-width: 1150px) {
            width: 450px;
        }
    }

    @include desktop-sm {
        margin-left: calc(var(--container-padding-size) / 2);
    }
}

.media_circle_1 {
    position: absolute;
    top: -87px;
    left: 300px;
    z-index: -1;
    width: 576px;
    height: 576px;

    @include tablet {
        display: none;
    }
}

.media_circle_2 {
    position: absolute;
    bottom: -24px;
    left: 0;
    z-index: -1;
    width: 468px;
    height: 545px;

    @include tablet {
        display: none;
    }
}

.signup_radio_wrap {
    width: 20px;
    height: 20px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
        border: 1px solid #afb4c5;
        border-radius: 50px;
        width: 100%;
        height: 100%;
        transform: translateY(-50%);
    }
}

.signup_radio_radio {
    display: none !important;
    position: static;
    margin-right: 18px;
    width: 20px;
    height: 20px;
    padding: 0;
    color: $color-accent;
    transition: $trans-def;

    .signup_radio_wrap::before {
        border-color: $color-accent;
    }

    .signup_radio_span {
        border-color: $color-accent;
    }

/*    &:hover {
        background-color: $color-light;

        ~ :global(.MuiFormControlLabel-label) {
            color: $color-light;
        }

        .signup_radio_wrap::before {
            border-color: $color-accent;
            background-color: $color-accent;
        }

        .signup_radio_span {
            border-color: $color-accent;
        }
    }*/

    div::before {
        transition: $trans-def;
    }

    &:global(.Mui-checked) {
        color: $color-light;

        div::before {
            border-color: $color-accent;
            background-color: $color-accent;
            transition: $trans-def;
        }

        ~ :global(.MuiFormControlLabel-label) {
            color: $color-light;
        }
    }
}

.radio_tip_box {
    position: relative;
}

.signup_radio {
    transition: $trans-def;
    position: relative;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
    height: 48px;
    border: 1px solid rgba($color-dark, 0.12);

    &:active {
        transform: scale(0.94);
    }

    &.checked_type {
        background-color: $color-accent;
        border-color: $color-accent;
    }

    .radio_box > div:first-of-type & {
        border-radius: 8px 0 0 8px;
        border-right: 1px solid transparent;
    }

    .radio_box > div:last-of-type & {
        border-radius: 0 8px 8px 0;
        border-left: 1px solid transparent;
    }

    :global(.MuiFormControlLabel-label) {
        position: relative;
        transition: none;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.02em;
    }

    &.checked_type {
        :global(.MuiFormControlLabel-label)::before {
            content: url("data:image/svg+xml, %3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 4.5L3.5 6.5L8.5 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            position: absolute;
            left: -18px;
            top: -2px;

            @include mobile {
                left: -15px;
            }
        }
    }

    &:hover {
        border-color: $color-accent;
        color: $color-accent;

        &:first-child {
            border-right: 1px solid $color-accent !important;
        }

        &:last-child {
            border-left: 1px solid $color-accent !important;
        }

        :global(.MuiFormControlLabel-label) {
            //color: white;
        }
    }
}

.signup_radio_icon {
    width: 20px;
    height: 20px;
}

.signup_radio_span {
    display: block;
    border: 1px solid #afb4c5;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    transition: $trans-def;
}

.tip {
    position: absolute;
    right: -30px;
    bottom: 16px;

    @include mobile {
        display: none;
    }
}

.terms {
    font-size: 14px;
    font-weight: 300;
    color: rgba($color-dark, 0.48);
    line-height: 1.3em;

    @include mobile {
        font-size: 14px;
    }

    &__link {
        color: $color-accent;
        text-decoration: underline;
        transition: $trans-def;
    }
}

.social_disable {
    pointer-events: none;
}

.linkFull {
    display: block;
    width: 100%;

    button {
        width: 100%;
    }
}

.user_type_title {
    margin-bottom: -5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: rgba($color-dark, 0.8);
}

.already_signin {
    text-align: center;
    font-weight: 600;

    .email_form + & {
        margin-top: 32px;
    }
}
