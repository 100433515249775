@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/global/settings/colors";
@import "../../assets/styles/global/settings/variables";
@import "../../assets/styles/mixins/container";

.ask_question {
    padding: 24px 0;
    color: $color-white;
    background-color: $color-accent;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 864px;

        @include tablet {
            padding: 0 20px;
        }

        @include mobile {
            flex-direction: column;
        }

        & a, & button{

            @include mobile {
                width: 100%;
            }
        }
    }

    &__title {
        font-size: 18px;
        line-height: 18px;

        @include mobile {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 20px;
            font-weight: 600;
            text-align: center;
        }

        span {
            color: rgba($color-white, 0.64);

            @include tablet {
                display: block;
                margin-top: 8px;
                font-weight: 400;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

    }

    &__button:hover{
        background: $color-white;
        border-color: $color-white;
        color:  $color-accent;
    }

}
