@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/extends/typography";


.container {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    padding: 32px;
    background: $color-white;
    box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 8px;
    transition: $trans-def;

    &:hover:not(.single_page) {
        box-shadow: 0 16px 24px rgba(0, 20, 91, 0.08), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);

        @include tablet {
            box-shadow: none;
        }
    }

    @include desktop {
        flex-wrap: wrap;
    }

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 16px;
    }
}

.link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.media {
    display: flex;
    align-items: center;
    margin-right: 24px;

    @include mobile {
        margin-right: 16px;
    }
}

.photo {
    margin-bottom: 8px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
    width: 80px;
    height: 80px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }

    @include mobile {
        width: 60px;
        height: 60px;
    }
}

.name {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $color-dark;

    &__verified {
        margin-left: 5px;

        path {
            stroke: $color-white;
            fill: $color-accent;
        }
    }

    @include tablet {
        margin-right: 8px;
    }

    @include mobile {
        margin-right: 4px;
        font-size: 16px;
        line-height: 20px;
    }
}

.badge {
    margin-left: 14px;
    padding: 5px 10px;
    border: 1px solid rgba($color-accent, 0.4);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1em;
    color: $color-accent;
}

.date {
    white-space: nowrap;
}

.statuses {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: rgba($color-dark, 0.64);

    @include mobile {
        margin-top: 7px;
        flex-direction: row;

        .date {
            margin-right: 10px;
        }
    }

    .single_page & {
        flex-direction: row;

        .date {
            margin-right: 10px;
        }
    }
}

.position {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba($color: $color-dark, $alpha: 0.8);

    @include mobile {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 14px;
    }
}

.rating {
    display: flex;
    align-items: center;

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
        color: rgba($color: $color-dark, $alpha: 0.64);
    }

    &__number {
        margin-right: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: $color-dark;
    }

    &__star {
        margin-right: 12px;
        margin-top: -2px;
        width: 16px;
        height: 16px;

        path {
            fill: $color-accent;
        }
    }
}

.main {
    width: 100%;

    @include desktop {
        padding-right: 0;

        &_expert {
            max-width: calc(100% - 88px);
        }
    }

    @include tablet {
        max-width: 100%;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include tablet {
            justify-content: flex-start;
        }

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            // padding-right: 48px;
        }

        .single_page & {
            flex-direction: column;
            align-items: flex-start;
            padding-right: 48px;

            .date {
                margin-right: 10px;
            }
        }

        &_top {
            display: flex;
            align-items: center;
        }

        &_wrap {
            width: 100%;
        }
    }

    &__info {
        margin-top: 8px;
    }

    &__information {
        display: flex;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 16px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 4px;
        }
    }

    &__skills {
        margin-bottom: 16px;

        @include mobile {
            margin-bottom: 12px;
        }

        &_label {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: rgba($color: $color-dark, $alpha: 0.8);

            @include mobile {
                font-size: 12px;
                line-height: 13px;
            }
        }

        &_desc {
            display: inline-block;
            margin-right: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $color-dark;

            &:not(:last-of-type) {
                &::after {
                    content: ",";
                    position: relative;
                    left: -2px;
                }
            }
        }

        & > div {
            flex-wrap: wrap;

            div {
                margin: 0 8px 8px 0 !important;
            }
        }
    }

    &__list {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 12px;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 6px;
            border-radius: 50%;
            width: 4px;
            height: 4px;
            background-color: $color-accent;
        }

        &_label {
            font-weight: 600;
            margin-right: 5px;
        }
    }
}

.file_link {
    display: flex;
    align-items: center;
    z-index: 2;
    color: $color-dark;

    &:hover {
        color: $color-accent;
    }

    svg {
        margin-right: 12px;
    }
}

.main_expert {
    padding-right: 180px;

    @include tablet {
        padding-right: initial;
    }
}

.after_line {
    display: flex;
    justify-content: space-between;

    &:not(:empty) {
        margin-top: 35px;
        padding-top: 24px;
        border-top: 1px solid rgba($color-dark, 0.12);
    }

    @include tablet {
        flex-direction: column;
    }
}

.skill_box {
    padding-right: 16px;

    .main__skills:last-of-type {
        margin-bottom: 0;
    }

}

.reply {
    margin-left: auto;
    min-width: 140px;
    z-index: 2;

    @include tablet {
        margin-top: 16px;
        width: 100%;
    }
}

.profession {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba($color: $color-dark, $alpha: 0.72);

    span {
        margin-right: 5px;

        &:not(:last-of-type) {
            &::after {
                content: ",";
                position: relative;
            }
        }
    }

    @include mobile {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.info {
    display: flex;
    align-items: center;
    margin: 16px 0;

    @include tablet {
        display: block;
    }

    &__price {
        margin-right: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: $color-accent;

        @include desktop {
            margin-right: 16px;
        }

        @include tablet {
            margin-right: 0;
            margin-bottom: 8px;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.72);
        }
    }

    &__type {
        display: flex;
        align-items: center;
        margin-right: 32px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #3e7eea;

        @include desktop {
            margin-right: 16px;
        }

        @include tablet {
            margin-right: 0;
            margin-bottom: 8px;
        }

        svg {
            margin-right: 8px;

            path {
                stroke: currentColor;
            }
        }

        &_session {
            color: #1cad70;
        }
    }

    &__profession {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $color-accent;
    }
}

.firstSession {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $color-orange;

    svg {
        margin-right: 5px;
    }

    @include mobile {
        margin-bottom: 12px;
    }
}

.job,
.location {
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color-dark, 0.72);

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }

    @include mobile {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.desc {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $color-dark;

    @include mobile {
        margin-bottom: 16px;
    }
}

.message {
    margin: 24px 0 32px;
    padding: 16px;
    border: 1px solid rgba($color-accent, 0.4);
    border-radius: 8px;
    font-weight: 400;
    color: $color-dark;
    white-space: pre-wrap;

    @include mobile {
        margin: 16px 0 22px;
    }
}

/*.buttons {
    position: relative;
    z-index: 2;
    width: 100%;

    @include desktop-lg-up {
        position: absolute;
        right: 32px;
        top: 50%;
        max-width: 140px;
        transform: translateY(-50%);
    }
}*/

.buttons {
    position: absolute;
    right: 32px;
    top: 50%;
    max-width: 140px;
    transform: translateY(-50%);
    z-index: 2;

    @include tablet {
        position: relative;
        width: 100%;

        transform: initial;
        right: initial;
        top: initial;
        max-width: initial;
    }
}

.go_to {
    margin-left: auto;
    white-space: nowrap;
}

.button {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.status {
    z-index: 2;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    :global(.MuiBadge-badge) {
        border: 1px solid $color-white;
        // right: 12%;
        // bottom: 12%;
    }

    &_online {
        :global(.MuiBadge-badge) {
            background-color: $color-green;
        }
    }

    &_offline {
        :global(.MuiBadge-badge) {
            background-color: $color-red;
        }
    }

    &__label {
        color: rgba($color-dark, 0.64);
        white-space: nowrap;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        b {
            font-weight: 700;
            color: $color-accent;
        }
    }

    &__date {
        color: $color-accent;
    }
}

.price {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    color: $color-accent;

    @include desktop-up {
        position: absolute;
        top: 32px;
        right: 32px;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
    }

}

.popup {
    [class*="popup-container"] {
        padding: 56px 72px 60px;
    }

    &-title {
        margin: 0;
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
    }

    &-text {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-dark;
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            width: calc(50% - 8px);
        }
    }
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    //margin-right: -148px;
    padding-top: 24px;
    border-top: 1px solid rgba($color-dark, 0.12);

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}

.user {
    display: flex;
    align-items: center;
    width: 50%;

    &__media {
        flex: 0 0 48px;
        margin-right: 12px;
        border-radius: 50%;
        max-width: 48px;

        img {
            object-fit: cover;
        }
    }

    &__status {
        :global(.MuiBadge-badge) {
            border: 1px solid $color-white;
        }

        &_online {
            :global(.MuiBadge-badge) {
                background-color: $color-green;
            }
        }

        &_offline {
            :global(.MuiBadge-badge) {
                background-color: $color-red;
            }
        }
    }

    &__name {
        width: 100%;
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
    }

    &__info {
        width: 100%;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.72);

        span {
            margin-left: 5px;
        }
    }
}

.edit {
    position: relative;
    z-index: 2;
    //margin-left: auto;
}

.project_balance_buttons {
    margin-top: 24px;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
            width: 100%;
        }
    }

    > * {
        &:first-child {
            margin-right: 24px;
        }

        @include mobile {
            width: 100%;
        }
    }
}

.money_not_reserved {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 5px 8px;
    width: fit-content;
    height: 24px;
    border-radius: 8px;
    background-color: rgba($color-accent, 0.08);
    font-size: 14px;
    line-height: 1em;
    color: $color-orange;

    &.is_reserve {
        background-color: rgba($color-green, 0.08);
        color: $color-green;
    }

    svg {
        margin-right: 5px;
    }
}

.is_reserve svg {
    path {
        stroke: $color-green;
    }
}

.amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.more_menu {
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: 2;

    @include mobile {
        right: 16px;
        top: 8px;
    }

    &__button {
        width: 36px;
        height: 36px;

        svg {
            pointer-events: none;
        }
    }

    ul {
        margin: 0;
        padding: 8px 0;

        li {
            margin-bottom: 0;
            padding-left: 16px;
            min-height: initial;
            font-weight: 400;
            font-size: 16px;
            line-height: 1em;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &::before {
                display: none;
            }
        }
    }
}
