@import "../../assets/styles/global/settings/variables";


.popup {
    :global(.MuiPaper-root) {
        max-width: 1117px;
        border-radius: 16px;

        @include desktop {
            max-width: calc(100% - 64px);
        }

        @include mobile {
            overflow: hidden;
            margin: 0;
            max-width: initial !important;
            width: calc(100% - var(--container-padding-size) * 2);
        }
    }
}

.container {
    position: relative;
    margin: auto;
    padding: 96px 80px 80px;
    min-width: 680px;
    max-width: 680px;
    background-color: white;

    @include tablet {
        padding: 64px;
        min-width: 580px;
        max-width: 580px;
    }

    @include mobile {
        padding: 24px;
        height: auto;
        min-width: 1px;
        max-width: 100%;
    }
}

.close {
    position: absolute !important;
    top: 26px;
    right: 26px;
    cursor: pointer;
    z-index: 1;

    @include mobile {
        top: 12px;
        right: 12px;
    }

    svg {
        width: 16px;
        height: 16px;
    }

    path {
        stroke: $color-accent;
        transition: $trans-fast;
    }

    &:hover path {
        stroke: $color-black;
    }
}

.title {
    margin: 0;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: $color-dark;

    @include mobile {
        margin-top: 10px;
        margin-right: 0px;
        margin-bottom: 24px !important;
        font-size: 24px;
        line-height: 28px;
    }
}

.text {
    margin: 0;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;

    @include tablet {
        font-size: 16px;
    }

    @include mobile {
        margin-bottom: 24px;
    }
}

.text_2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 31px;

    @include tablet {
        font-size: 24px;
        line-height: 1em;
    }

    @include mobile {
        font-size: 20px;
    }
}

.text_3 {
    font-size: 25px;
    font-weight: 700;
    line-height: 1.5em;
    color: #D6E7ED;

    @include tablet {
        font-size: 20px;
        line-height: 1em;
    }

    @include mobile {
        font-size: 18px;
    }
}

.text_4 {
    font-size: 15px;
    line-height: 2.4em !important;
    color: #B3BEC3;

    @include mobile {
        font-size: 12px;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    a,
    button {
        width: 50%;

        &:global(.MuiButton-root) {
            padding-right: 20px;
            padding-left: 20px;
        }

        @include mobile {
            width: 100%;
        }
    }

    @include mobile {
        flex-direction: column;
    }
}
