@import "../.././assets/styles/global/settings/variables";

.wrapper {
    position: relative;
    display: flex;
    height: calc(100vh - 168px);
    height: calc(var(--vh) - 168px);

    @include tablet {
        flex-direction: column;
        height: calc(100vh - 64px);
        height: calc(var(--vh) - 64px);
        margin: calc(var(--container-padding-size) * -1);
        padding: calc(var(--container-padding-size));
        overflow: hidden;
    }
}

.sidebar {
    width: 412px;
    height: 100%;

    &.cabinet {
        padding-right: 32px;
    }

    @include desktop {
        width: 304px;
    }

    @include tablet {
        width: 100%;
    }

    &_hide {
        display: none;
    }
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -48px;
    margin-bottom: -48px;
    margin-left: 32px;
    padding: 32px 0 32px 32px;
    width: calc(100% - 412px);
    height: calc(100vh - 72px);
    height: calc(var(--vh) - 72px);
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    @include tablet {
        padding: 0;
        width: auto;
        margin: calc(var(--container-padding-size) * -1);
    }

    &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &_hide {
        display: none;
    }

    &__body {
        position: relative;
        width: 100%;
        flex: 1;
        margin-bottom: 24px;
    }
}

.scroller {
    position: relative;

    @include tablet {
        margin-top: 64px;
        // margin-bottom: 84px;
        height: calc(100% - 64px) !important;
    }

    @include mobile {
        margin-top: 54px;
        // margin-bottom: 84px;
        height: calc(100% - 54px) !important;
    }
}

.view {
    padding-right: 32px;

    @include tablet {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &__input {
        width: 100%;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 16px 16px;
        padding-right: 56px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: $color-white;
        box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

        @include tablet {
            padding: 12px 16px;
        }
    }

    &__submit {
        position: absolute;
        top: 20px;
        right: 16px;
        border: none;
        color: $color-accent;
        background: none;
        pointer-events: none;

        svg {
            width: 18px;
            height: 18px;
        }

        @include tablet {
            top: 16px;
        }
    }
}

.list {
    margin-top: 16px;
    height: calc(100% - 108px);

    @include tablet {
        margin-right: -16px;
        margin-left: -16px;
        height: calc(100% - 110px);
    }
}

.messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
}
