// timeline component
@import "../.././assets/styles/global/settings/variables";
@import "../.././assets/styles/mixins/container";

.main {
    padding-bottom: 120px;
}

.main {
    @extend .container;

    @include tablet {
        padding-top: 48px;
    }
}

.grid {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.grid__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2px;
    justify-content: stretch;

    @include mobile {
        margin: 0px;
    }

}

.timeline {
    grid-column-start: 1;
    grid-column-end: 3;
    letter-spacing: -0.02em;

    @include tablet {
        margin-bottom: 48px;
        grid-column-end: 2;
    }

    &__points {
        border-bottom: 1px solid rgba($color-dark, 0.12);
        display: flex;
    }

    &__point {
        padding: 0 20px;
        margin-bottom: -2px;
        font-size: 20px;
        line-height: 1em;
        font-weight: bold;
        font-family: $font-family-base;
        color: $color-dark;
        cursor: pointer;
        transition: $trans-def;

        &:first-of-type {
            padding-left: 0;
        }

        @include mobile {
            padding: 0 12px;
            font-size: 18px;
        }

        &_is_active {
            border-color: $color-accent;
            color: $color-accent;
        }
    }

    &__inner_point {
        padding-bottom: 25px;
        border-bottom: 2px solid transparent;

        @include mobile {
            padding-bottom: 24px;
        }

        &:hover,
        .timeline__point_is_active & {
            border-color: $color-accent;
            color: $color-accent;
        }
    }
}

.more__wrapper {
    grid-column: 2 / 2;
    padding-left: 290px;
    margin-top: 96px;

    @include tablet {
        grid-column: 1 / 2;
        margin: 64px auto 0;
        padding-left: 0;
    }

    @include mobile {
        margin-top: 40px;
        text-align: center;
    }
}

.pagination{
    margin-top: 32px;
}
