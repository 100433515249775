@import "../../assets/styles/global/settings/variables";


.signup_button {
    width: 100%;

    svg {
        position: absolute;
        left: 24px;

        @include mobile {
            left: 16px;
        }
    }
}
