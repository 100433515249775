@import "normalize.css/normalize";
// settings
@import "settings/variables";
@import "src/components/WithStyledTags/withStyledTags.module";

:root {
    --vh: 100vh;
    --container-width: 1312px;
    --container-padding-size: 64px;

    --swiper-theme-color: #{$color-accent} !important;
    --swiper-pagination-bullet-inactive-color: #{$color-accent};
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-horizontal-gap: 12px;

    @include tablet {
        --container-padding-size: 32px;
    }
    @include mobile {
        --container-padding-size: 20px;

        --swiper-pagination-bullet-horizontal-gap: 8px;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
// Не вызывает performance issues https://stackoverflow.com/questions/37444066/is-applying-a-css-transition-on-all-elements-a-performance-concern
// Исключения добавляются через :not()
html {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: var(--vh);
    box-sizing: border-box;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    text-decoration-skip: objects;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

main {
    flex: 1;
}

p {
    margin: 0;
}

a,
button {
    outline: none;
    transition: $trans-def;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: currentColor;

    &:hover {
        color: $color-dark;
    }
}

.color-accent {
    color: $color-accent;
}

.color-dark {
    color: $color-dark;
}

figure,
picture {
    display: inline-block;
    margin: 0;
    line-height: 0;

    img {
        width: 100%;
        transform: translateZ(0);
    }
}

img {
    vertical-align: top;
    max-width: 100%;
    transform: translateZ(0);
    user-select: none;
}

img[draggable="false"] {
    pointer-events: none;
    // stylelint-disable-next-line
    -webkit-user-drag: none;
}

fieldset {
    margin: 0;
    border: 0;
    padding: 0;
}

ul,
ol,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

video {
    outline: none;
    width: 100%;
    height: 100%;
}

iframe {
    display: block;
}

/*
* Немедленно переместите любую анимацию в конечную точку, если пользователь установил свое устройство в положение "prefers reduced motion".
* Это может привести к плохим(непреднамеренным) последствиям. Удалите по мере необходимости и напишите свой собственный код для prefers-reduced-motion.
*/
@media (prefers-reduced-motion: reduce) {
    *,
    *:before,
    *:after {
/*        animation-duration: 0.001s !important;
        animation-delay: 0s !important;
        transition-duration: 0.001s !important;
        transition-delay: 0s !important;*/
    }
}

.main {
	display: flex;
	flex-direction: column;
	// stylelint-disable-next-line
	min-height: 100vh; // fallback
	// stylelint-disable-next-line
	min-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
}

.transition-def {
    transition: $trans-def;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.disabled {
    pointer-events: none !important;
    opacity: 0.4;
}

.disabled-mask {
    pointer-events: none !important;

/*    &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        //opacity: 0.1;
        //background-color: $color-black;
        z-index: 1302;
    }*/
}

.error {
    color: $color-red;
}

.relative {
    position: relative;
}

.display-flex {
    display: flex;
}

.flex-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.display-none {
    display: none;
}

.text-center {
    text-align: center;
}

.hidden {
    visibility: hidden;
}

.width-100 {
    width: 100%;
}

.green {
    color: $color-green;
}

.red,
.error {
    color: $color-red;
}

//https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.btn {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border: 0;
	padding: 0;
	text-align: center;
	background-color: transparent;
	transition: $trans-def;
	user-select: none;
    cursor: pointer;

	&__content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 100%;
		color: currentColor;
		transform: translateZ(0);
	}
}

pre {
    display: block;
    margin: 10px 0 10px;
    padding: 9.5px;
    width: 100%;
    overflow: auto;
    font-size: 13px;
    line-height: 1.4;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Mui-selected.PrivatePickersYear-yearButton {
    //color: $color-dark !important;
    //background-color: white !important;
}

.scroll-is-disabled {
    overflow: hidden;
    margin-top: 0;
    max-height: var(--vh);
}

.popup-title {
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: $color-dark;

    @include mobile {
        margin-top: 10px;
        margin-right: 0;
        margin-bottom: 24px !important;
        font-size: 24px;
        line-height: 28px;
    }
}

.popup-desc {
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 1.5em;
    color: $color-dark;

    @include mobile {
        margin-bottom: 20px;
    }
}

.popup-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > * {
        width: calc(50%);

        @include mobile {
            width: 100%;
        }
    }

    .MuiButton-root {
        padding-right: 20px;
        padding-left: 20px;
    }

    @include mobile {
        flex-direction: column;
    }
}

.status {
    .MuiBadge-badge {
        border: 1px solid $color-white;
        border-radius: 50%;
    }

    &_online {
        .MuiBadge-badge {
            background-color: $color-green;
        }
    }

    &_offline {
        .MuiBadge-badge {
            background-color: $color-red;
        }
    }

    &_big {
        .MuiBadge-badge {
            min-width: 12px;
            height: 12px;
        }
    }
}

.two-buttons {
    display: flex;
    justify-content: center;
    grid-column-gap: 16px;

    @include mobile {
        flex-direction: column;
        grid-row-gap: 16px;
    }
}

.star-selecting {
    transform: scale(1.1);

    path {
        fill: $color-orange !important;
    }
}

.input-number input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
}

.current-ballance {
    margin-bottom: 32px;
    text-align: right;
}
