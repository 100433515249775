@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";
@import '../../assets/styles/extends/section';


.hero_inner {
    position: relative;
    z-index: 1;
    padding-top: 24px;

    &::before {
        content: '';
        position: absolute;
        top: -162px;
        left: -140px;
        z-index: -1;
        width: 1152px;
        height: 812px;
        background: #faf9f7;
    }
}

.hero {
    @extend .container;
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-areas:
        'search_block featured_employee'
        'scroll_down featured_employee';
    grid-template-columns: 35% 60%;
    justify-content: space-between;
    row-gap: 35px;
    padding-bottom: 90px;

    &::before {
        content: '';
        position: absolute;
        top: -162px;
        left: 0;
        z-index: -1;
        border-bottom-right-radius: 40px;
        width: 1152px;
        height: 812px;
        background: #faf9f7;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        row-gap: 0;
    }

    &__content {
        padding-top: 64px;

        @include tablet {
            padding-top: 0px;
            margin-bottom: 32px;
            text-align: center;
        }
    }

    &__title {
        @extend .heading_lvl_1;
        margin-bottom: 56px;
        font-weight: 700;
        font-size: 56px;
        line-height: 60px;
        letter-spacing: -0.01em;
        color: $color-dark;

        span {
            color: $color-accent;
        }

        @include tablet {
            text-align: center;
            margin-bottom: 24px;
        }

        @include mobile {
            font-size: 32px;
            line-height: 36px;
        }
    }

    &__label {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-accent;

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 12px;
        }
    }

    &__subtitle {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.01em;
        color: $color-dark;

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 8px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__text {
        margin-bottom: 48px;
        max-width: 416px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba($color-dark, 0.8);

        @include tablet {
            text-align: center;
            margin: 0 auto 24px;
        }

        @include mobile {
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__button {
        max-width: 416px;
    }

    &__btn {
        margin-top: 48px;

        @include mobile {
            margin-top: 24px;
        }
    }

    &__picture {
        position: relative;
        margin-left: auto;
        width: 752px;
        height: 528px;
        overflow: hidden;
        border-radius: 0 40px 0 40px;

        @include desktop-sm {
            width: auto;
            height: auto;
        }
    }
}

.features {
    @extend .container;
    padding: 124px 0 88px;

    @include tablet {
        padding: 48px 0;
    }

    @include mobile {
        padding: 48px 0 56px 0;
    }

    &__header {
        text-align: center;
        flex-direction: column;
    }

    &__subtitle {
        @extend .section_subtitle;

        @include mobile {
            margin-bottom: 8px;
        }
    }

    &__title {
        @extend .section_title;
        margin-bottom: 32px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &__cards {
        @extend .container;
        position: relative;

        &_slider {
            margin: 0 -24px;
            padding: 16px 24px 32px;
        }

        @include tablet {
            padding: 0 20px;
        }

        @include mobile {
            padding: 0;
        }
    }

    &__pagination {
        bottom: -14px !important;
    }

    &__cards_mobile {
        display: none;

        @include mobile {
            display: block;
        }
    }

    &__card {
        @include mobile {
            width: calc(100vw - 40px);
            margin: 0 20px;
        }
    }

    &__slide {
        margin: 10px 0 88px 0;
    }
}

.plans {
    padding: 118px 0 124px;
    background: $color-accent-reverse;

    @include tablet {
        padding: 48px 0;
    }

    @include mobile {
        padding: 48px 0 0px;
    }

    &__container {
        @extend .container;
    }

    &__header {
        text-align: center;
        flex-direction: column;
    }

    &__subtitle {
        @extend .section_subtitle;
    }

    &__title {
        @extend .section_title;
        margin-bottom: 66px;

        @include mobile {
            margin-bottom: 40px !important;
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 32px;

        @include tablet {
            padding: 0 20px;
            display: block;
        }

        @include mobile {
            padding: 0;
        }

        @include mobile {

        }
    }
}
