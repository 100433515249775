@import '../../assets/styles/global/settings/variables';


.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--vh);
    //min-height: 100vh;
    min-height: var(--vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 30;
	//transition: 0.3s ease-in-out;
    transition: 0s;
    background-color: $color-accent-reverse;
    visibility: hidden;
    opacity: 0;
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	@include mobile {
		transform: translate(-50%, -50%) scale(1) !important;
	}
}
// active state
.preloader.is_visible {
    visibility: visible;
    opacity: 1;
    //transition-delay: .4s;
    //transition: $trans-def;
}
