@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";

.list {
    margin-bottom: 40px;

    @include tablet {
        padding: 32px;
        display: flex;
        flex-direction: column;
    }

    @include mobile {
        padding: 24px;
    }
}

.link {
    display: none;

    @include desktop-up {
        display: block;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.button {
    &:global(.MuiButton-root) {
        justify-content: flex-start;
        text-align: left;
        padding-left: 32px;
        padding-right: 32px;

        &:global(.MuiButton-textPrimary) {
            color: rgba($color-dark, 0.64);
            // color: $color-accent;
        }
    }

    svg {
        width: 18px;
        height: 18px;
        margin-right: 20px;
    }
}

.help_mobile {
    display: none;
    margin-top: auto;

    @include burger {
        display: block;
    }
}
