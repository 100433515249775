@import '../../../assets/styles/global/settings/variables';


.section_main {
    width: 83%;

    @include mobile {
        width: 100%;
    }
}

.step_number {
    height: 32px;
    width: 32px;
    border: 1px solid rgba($color-dark, 0.24);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1em;
    color: rgba($color-dark, 0.48);

    @include mobile {
        display: none;
    }
}

.radio_tip {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include mobile {
        width: 100%;
        position: relative;
    }

    > div:last-child{

        @include mobile {
            position: absolute;
            right: 0;
            top: -26px;
        }
    }
}
.radio_tip {
    @include mobile {
        position: static;
    }
}

.radio {
    @include mobile {
        width: 100%;
    }
}

.radio_button {
    @include mobile {
        width: calc(50% - 8px);
        min-width: inherit;
    }
}

.radio_button2 {
    @include mobile {
        &:not(:first-child) {
            margin-left: 0 !important;
            margin-top: 16px !important;
        }
    }
}

.fixed_price {
    @include mobile {
        margin-bottom: 16px;
    }
}

.pay_switcher {
    margin-bottom: 16px;
}

.save {
    margin-top: 32px !important;

    @include mobile {
        margin-top: 24px !important;
        width: 100%;
    }
}

.upload_photo {
    display: flex;
    align-items: center;
}

.file_link {
    margin-right: 11px;

    &:hover {
        color: $color-accent;
    }
}

.upload {
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
}

.upload_text {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1em;
    color: rgba($color-dark, 0.48);

    svg {
        margin-right: 11px;
    }

    &:hover {
        color: $color-accent;
    }
}

.back {
    margin-top: 16px !important;

    @include tablet {
        margin-top: 0 !important;
    }
}
