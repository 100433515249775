@import "../../assets/styles/global/settings/variables";
@import "../../assets/styles/mixins/breakpoints";

.with_styled_tags {
    color: $color-dark;

    h1 {
        margin: 0 0 56px;
        font-size: 56px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.01em;

        @include mobile {
            margin: 0 0 40px;
            font-size: 32px;
            line-height: 36px;
        }
    }

    h2 {
        margin: 0 0 48px;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.01em;

        @include mobile {
            margin: 0 0 32px;
            font-size: 28px;
            line-height: 32px;
        }
    }

    h3 {
        margin: 46px 0 36px;
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.01em;

        @include mobile {
            margin: 0 0 24px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    h4 {
        margin: 34px 0 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.01em;

        @include mobile {
            font-size: 18px;
            line-height: 22px;
        }
    }

    h5 {
        margin: 34px 0 24px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.01em;

        @include mobile {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    p {
        margin-bottom: 14px;
        font-size: 16px;
        line-height: 24px;
        color: rgba($color-dark, 0.8);

        @include mobile {
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 24px;
        }

        &:last-of-type {
            //margin-bottom: 0;
        }
    }

    a {
        color: $color-accent;
        transition: $trans-def;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
    }

    ul {
        margin: 32px 0;
        color: rgba($color-dark, 0.8);

        li {
            position: relative;
            padding-left: 44px;

            &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-accent;
            }

            @include mobile {
                padding-left: 28px;
            }
        }

        @include mobile {
            margin: 20px 0;
        }
    }

    ol {
        margin: 24px 0;
        padding-left: 0;
        color: rgba($color-dark, 0.8);
        counter-reset: item;

        li {
            padding-left: 0;

            &:before {
                content: counters(item, ".") ". ";
                margin-right: 8px;
                display: inline-block;
                width: 32px;
                counter-increment: item;
                color: $color-accent;
            }
        }

        @include mobile {
            margin: 20px 0;
        }
    }

    table {
        width: 100%;
        border: 1px solid rgba($color-dark, 0.08);
        border-radius: 24px;
        border-spacing: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @include desktop {
            min-width: 800px;
        }

        @include mobile {
            border-radius: 16px;
        }

        @include mobile {
            border-radius: 8px;
        }

        thead {
            border-top-right-radius: 24px;
            border-top-left-radius: 24px;
            padding: 14px 5px;
            text-align: left;
            color: $color-accent;
            background-color: rgba($color-accent, 0.04);

            @include mobile {
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
            }

            @include mobile {
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
            }
        }

        td,
        th {
            padding: 24px 32px;
        }

        td {
            border-bottom: 1px solid rgba($color-dark, 0.08);
        }

        tr:not(:last-of-type),
        thead {
            border-bottom: 1px solid rgba($color-dark, 0.08);

        }

        @include mobile {
            th,
            td {
                padding: 16px;
            }
        }
    }
}
