@import "../../assets/styles/global/settings/variables";

.systemMessage {
    margin: 24px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: rgba($color-dark, 0.64);
}

.status {
    &:global(.MuiBadge-root) {
        margin: 0;
    }

    :global(.MuiBadge-badge) {
        border: 1px solid $color-white;
    }

    &_online {
        :global(.MuiBadge-badge) {
            background-color: $color-green;
        }
    }

    &_offline {
        :global(.MuiBadge-badge) {
            background-color: $color-red;
        }
    }
}

.message {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.content {
    position: relative;
    max-width: 600px;

    @media (max-width: 1295px) {
        max-width: calc(100% - 40px);
    }

    @media (max-width: 1023px) {
        max-width: 600px;
    }

    @media (max-width: 700px) {
        max-width: calc(100% - 40px);
    }

    &:not(:first-of-type) {
        margin-top: 12px;
    }

    &:hover {
        .time,
        .bottom {
            opacity: 1;
        }
    }

    &:not(:first-child) {
        margin-top: 12px;
    }

    &.content_interlocutor {
        margin-right: auto;

        .text {
            background: rgba($color-dark, 0.04);
        }
    }

    &.content_my {
        align-items: flex-end;
        margin-left: auto;

        .text {
            background: rgba($color-accent, 0.04);
        }

        .time {
            left: auto;
            right: calc(100% + 8px);
        }
    }
}

.content_my_time {
    text-align: end;
}

.text {
    width: 100%;
    border-radius: 8px;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-dark;
    background: rgba($color-dark, 0.04);

    &:hover {
        background: rgba($color-dark, 0.08);
    }
}

.media {
    position: sticky;
    top: 8px;
    bottom: 8px;
    display: inline-block;
    flex: 0 0 32px;
    margin-right: 8px;
    border-radius: 50%;
    max-width: 32px;

    &:not(:first-child) {
        display: none;
    }

    img {
        object-fit: cover;
    }
}

.bottom {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 2;
    padding: 4px;
    border-radius: 4px;
    align-items: center;
    background-color: $color-white;
    opacity: 0;
    transition: $trans-def;
    display: none;
}

.time {
    //position: absolute;
    //left: calc(100% + 8px);
    //bottom: 8px;
    margin-top: 1px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba($color-dark, 0.64);
    transition: $trans-def;
}

.tooltip {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: rgba($color-dark, 0.48);

    &:not(:last-child) {
        margin-right: 16px;
    }

    svg {
        margin-right: 5px;
        width: 11px;
        height: 11px;
        color: currentColor;
    }

    &:hover {
        color: $color-red;
    }
}

.file {
    display: flex;
    align-items: center;
    width: max-content;
    border-radius: 8px;
    padding: 10px 12px;
    background: rgba($color-dark, 0.04);

    &:hover {
        background: rgba($color-dark, 0.08);
    }

    &__content {
        margin-left: 12px;
    }

    &__media {
        width: 32px;
        height: 32px;

        img {
            object-fit: cover;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-dark;
    }

    &__size {
        font-weight: 500;
        margin-top: 2px;
        font-size: 12px;
        line-height: 12px;
        color: rgba($color-dark, 0.7);
    }
}

.session {
    width: 100%;
    margin-top: 12px;
    border: 1px solid rgba($color-dark, 0.12);
    border-radius: 8px;
    padding: 24px;
    text-align: center;

    &__text {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
    }

    &__work {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;

        & + .session__buttons {
            margin-top: 20px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        grid-column-gap: 16px;

        > * {
            min-width: 152px !important;
        }

        @include mobile {
            flex-direction: column;
            gap: 12px;
        }
    }

    &__popup {
        [class*="popup-container"] {
            padding: 56px 72px 60px;
        }

        &-title {
            margin: 0;
            margin-bottom: 32px;
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: -0.01em;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        &-media {
            flex: 0 0 40px;
            overflow: hidden;
            margin-right: 16px;
            border-radius: 50%;
            max-width: 40px;
            height: 40px;

            img {
                object-fit: cover;
            }
        }

        &-name {
            width: 100%;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.01em;

            span {
                margin-left: 5px;
                letter-spacing: 0;
                color: rgba($color-dark, 0.8);
            }
        }
    }
}

.joinMeeting {
    margin-top: 12px;
    width: 100%;
    text-align: center;
}
