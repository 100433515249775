@import "../.././../assets/styles/global/settings/variables";
@import "../.././../assets/styles/mixins/container";

.button {
    justify-content: unset;
    padding: 8px 16px;
    border-radius: 8px;
    transition: $trans-def;
    cursor: pointer;

    &:hover {
        background-color: rgba($color-accent, 0.08);
    }

    &_active {
        background-color: rgba($color-accent, 0.08);
    }

    &_is_notify {
        &::after {
            content: '';
            position: absolute;
            right: 0;
            height: 10px;
            width: 10px;
            background-color: $color-red;
            border-radius: 50%;
        }
    }
}

.media {
    flex: 0 0 56px;
    margin-right: 12px;
    border-radius: 50%;
    max-width: 56px;

    img {
        object-fit: cover;
    }

    @include tablet {
        flex: 0 0 48px;
        max-width: 48px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 66px);
    text-align: left;
}

.name {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.text {
    width: calc(100% - 44px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba($color-dark, 0.8);
}

.date {
    position: relative;
    margin-top: -18px;
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba($color-dark, 0.8);
}

.dots {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;

    &__button {
        width: 36px;
        height: 36px;

        svg {
            pointer-events: none;
        }
    }

    ul {
        margin: 0;
        padding: 8px 0;

        li {
            margin-bottom: 0;
            padding-left: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &::before {
                display: none;
            }
        }
    }
}
