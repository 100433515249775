@import '../../assets/styles/global/settings/colors';
@import '../../assets/styles/mixins/breakpoints';

.item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 32px;
    background: $color-white;
    box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 8px;

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 16px;
    }
}

.info {
    margin-right: 20px;
    width: calc(90% - 20px);
    display: flex;

    @include mobile {
        margin-right: 0;
        margin-bottom: 25px;
        width: 100%;
    }
}

.avatar {
    margin-right: 32px;
    margin-bottom: 11px;
    width: 96px;
    height: 96px;
    min-width: 96px;
    font-size: 13px;
    border-radius: 50%;
    background-color: rgba($color-dark, 0.06);

    @include mobile {
        margin-bottom: 0;
        margin-right: 12px;
    }
}

.doctor_info {
    width: 100%;

    @include mobile {
        justify-content: space-around;
        width: 100%;
        padding: 20px 0;
    }
}

.box {
    background-color: rgba($color-dark, 0.06);
}

.fill {
    margin-bottom: 16px;
    width: 80px;
    height: 16px;
    background-color: rgba($color-dark, 0.06);

    &:nth-child(1) {
        background-color: rgba($color-dark, 0.2);
    }

    &:nth-child(2) {
        width: 95%;
    }

    &:nth-child(3) {
        width: 61%;
    }
}

