@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/mixins/container";
@import "../../assets/styles/global/settings/variables";

.back {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;
    max-width: 120px;

    @include mobile {
        margin-bottom: 20px;
    }

    svg {
        display: inline-block;
        margin-right: 14px;

        path {
            transition: $trans-def;
        }
    }

    &:hover {
        color: $color-accent;

        path {
            stroke: $color-accent;
        }
    }

    @include mobile {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 43px;
    }
}

.wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;

    @include tablet {
        display: block;
    }
}

.sidebar {
    margin-top: -4px;
    margin-right: 32px;
    width: 304px;

    &__user {
        position: relative;
        margin-bottom: 16px;
        padding: 32px;
        border-radius: 16px;
        background: $color-white;
        box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

        @include mobile {
            border-radius: 12px;
            padding: 16px;
        }

        &-media {
            margin-bottom: 24px;

            @include mobile {
                margin-bottom: 16px;
            }

            > div {
                @include tablet {
                    margin: 0 auto 16px;
                }

                @include mobile {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        &-name {
            display: flex;
            align-items: center;
            margin-bottom: 12px !important;

            @include tablet {
                display: block;
                text-align: center;
            }

            @include mobile {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 8px !important;
            }
        }

        &-freelance {
            margin-bottom: 24px;
            font-size: 14px;
            line-height: 14px;
            opacity: 0.64;

            &:first-child {
                margin-top: 8px;
            }

            @include tablet {
                text-align: center;
            }
        }

        &-buttons {
            display: flex;
            align-items: center;
            margin-top: 32px;

            @include mobile {
                margin-top: 20px;
            }
        }

        &-info {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.64);

            span {
                display: block;
                margin-bottom: 4px;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: $color-accent;
            }
        }

        &-dots {
            &:global(.MuiButton-root) {
                margin-left: 16px;
                padding: 20px;
                height: 54px;
                max-width: 48px;
                color: $color-accent;

                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    @include tablet {
        width: 100%;
    }
}

.rating {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
        color: rgba($color: $color-dark, $alpha: 0.64);
    }

    &__number {
        margin-right: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: $color-dark;
    }

    &__star {
        margin-right: 12px;
        margin-top: -2px;
        width: 16px;
        height: 16px;

        path {
            fill: $color-accent;
        }
    }

    @include tablet {
        text-align: center;
    }

    @include mobile {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 23px;
    }
}

.verify {
    position: relative;
    display: block;
    margin-bottom: 16px;
    border: 1px solid rgba($color-accent, 1);
    border-radius: 16px;
    padding: 32px 32px 32px 60px;
    background-color: $color-white;

    @include mobile {
        display: none;
    }

    &__icon {
        position: absolute;
        top: 35px;
        left: 32px;
        width: 16px;
        height: 16px;
    }

    &__title {
        margin-bottom: 16px !important;
    }

    &__text {
        color: rgba($color-dark, 0.64);
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 304px - 32px);
    min-height: 100%;

    @include tablet {
        width: 100%;
    }
}

.body {
    position: relative;
    display: flex;
    margin-top: 52px;
    margin-bottom: 16px;
    padding: 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    @include desktop-sm {
        display: block;
    }

    @include mobile {
        margin-top: 12px;
        padding: 16px;
    }
}

.main {
    &__content {
        flex: 1;
        max-width: 602px;
        margin-right: 94px;

        @include desktop-sm {
            max-width: 100%;
            margin-right: 0px;
        }
    }

    &__right {
        flex: 0 0 278px;
        padding-top: 92px;
        max-width: 278px;

        @include desktop-sm {
            padding-top: 0px;
            max-width: 100%;
        }

        @include mobile {
            margin-bottom: -16px;
        }
    }

    &__information {
        display: flex;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 32px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 25px;
            margin-bottom: 48px;
        }
    }

    &__files {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &__skills {
        margin: 32px 0;

        @include mobile {
            margin: 24px 0;
        }

        &-title {
            margin-bottom: 24px !important;

            @include mobile {
                margin-bottom: 16px !important;
            }
        }

        &-label {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: rgba($color: $color-dark, $alpha: 0.8);
            width: 100%;

            @include mobile {
                font-size: 12px;
                line-height: 13px;
            }
        }

        &-desk {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $color-dark;
        }

        &-list {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            &:not(:last-child) {
                padding-bottom: 8px;
            }

            @include mobile {
                font-size: 14px;
                line-height: 18px;
            }

            li {
                position: relative;
                display: block;
                padding-left: 24px;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    background-color: $color-accent;
                    transform: translateY(-50%);
                }

            }

            &_special {
                li {
                    &:not(:last-child) {
                        margin-bottom: 24px;
                    }
                }

                :global(.text) {
                    margin-bottom: 24px;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -24px;
                        top: 50%;
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        background-color: $color-accent;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        & > div {
            flex-wrap: wrap;

        }


    }

    &__services {
        align-self: center;
    }

    &__stack {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 16px;
        }

        > div {
            flex-wrap: wrap;

            > div {
                margin: 0 8px 8px 0 !important;

                @include mobile {
                    margin: 0 6px 6px 0 !important;
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }
}

.job,
.location {
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color: $color-dark, $alpha: 0.72);

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }

    @include mobile {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.firstText {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $color-accent;

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }
}

.desc {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;

    @include mobile {
        margin-bottom: 16px;
    }
}


.file {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 32px;
    color: $color-dark;

    &__content {
        margin-left: 12px;
    }

    &__media {
        width: 32px;
        height: 32px;

        img {
            object-fit: cover;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    &__size {
        font-weight: 500;
        margin-top: 2px;
        font-size: 12px;
        line-height: 12px;
        opacity: 0.7;
    }

    &__year {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin-left: 5px;
    }

    &:hover {
        color: $color-accent;
    }
}


.step {
    margin: 32px 0;

    @include mobile {
        margin: 24px 0;
    }

    &__item {
        &:last-of-type {
            .step__icon::before {
                display: none;
            }
        }

        &:not(:last-of-type) {
            padding-bottom: 16px;
        }

        :global(.MuiStepLabel-labelContainer) {
            max-width: calc(100% - 16px);
        }

        :global(.MuiStepLabel-label) {
            display: flex;
            line-height: 0.75;

            @include mobile {
                flex-direction: column;
            }
        }

        // &_year {
        //     :global(.MuiStepLabel-label) {
        //         flex-direction: row;
        //     }
        // }

        // &_certificate {
        //     &:not(:last-of-type) {
        //         margin-bottom: 32px;
        //     }
        // }
    }

    &__divider {
        display: inline-block;
        margin: 0 8px;
        font-size: 24px;
        line-height: 24px;
    }

    &__label {
        &:global(.MuiStepLabel-root) {
            align-items: flex-start;
            padding: 0;
            line-height: 0.75;
        }
    }

    &__title {
        flex: 0 0 auto;
        margin-right: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @include mobile {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        b {
            margin-right: 8px;
            font-weight: 500;
        }
    }

    // &__icon {
    //     position: relative;
    //     margin-top: 7px;
    //     margin-right: 8px;
    //     border-radius: 50%;
    //     width: 8px;
    //     height: 8px;
    //     background: rgba($color-dark, 0.12);

    //     &::before {
    //         content: '';
    //         position: absolute;
    //         left: 3px;
    //         top: 100%;
    //         width: 1px;
    //         height: 20px;
    //         background-color: rgba($color-dark, 0.12);
    //     }

    //     &_active {
    //         background-color: $color-accent;
    //     }
    // }

    &__iconDot {
        margin-top: 7px;
        margin-right: 8px;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: rgba($color-dark, 0.12);

        &_active {
            background-color: $color-accent;
        }
    }

    &__connector {
        flex: 1 0 auto;
        margin-left: 4px;
    }

    &__connectorDot {
        flex: 1 0 auto;
        margin-left: 3px;
    }

    &__line {
        margin: -24px 0 -12px;
        width: 1px;
        height: 100%;
        min-height: 30px;
        background: rgba($color-dark, 0.12);

        @include mobile {
            margin: -42px 0 -12px;
            min-height: 49px;
        }
    }
}

.reviews {
    position: relative;
    margin-top: 16px;
    margin-bottom: 64px;
    padding: 28px 32px 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    @include tablet {
        width: 100%;
    }

    @include mobile {
        padding: 16px;
        margin: 0 0 32px;
    }

    &__title {
        margin-bottom: 32px;

        span {
            margin-left: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.64);
        }
    }

    &__layout {
        margin-top: 24px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-top: 32px;

        @include mobile {
            display: block;
            margin-top: 20px;
        }
    }

    &__button {
        margin-right: 32px !important;

        @include tablet {
            margin-right: 24px !important;
        }

        @include mobile {
            margin-top: 16px !important;
            margin-right: 0 !important;
        }
    }

    &__stars {
        color: $color-accent;
    }
}

.review {
    margin: 32px 0;
    max-width: 602px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include mobile {
        margin: 30px 0 20px;
    }

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &__avatar {
        margin-right: 12px;
    }

    &__name {
        margin-right: 12px;
    }

    &__stars {
        display: flex;
        align-items: center;
        color: $color-accent;

        svg {
            margin-left: 4px;
            color: currentColor;
            fill: currentColor;
        }
    }

    &__date {
        margin-left: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.64);
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: rgba($color-dark, 0.8);
    }
}

.profileEdit_mobile {
    display: none;

    @include tablet {
        display: block;
        margin-bottom: 24px !important;
    }
}

.profileEdit_desktop {
    position: absolute;
    right: 0;
    top: 0;

    @include desktop {
        display: none;
    }
}

.profileEdit {
    margin-left: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none !important;
    color: $color-dark;
    transition: $trans-def;

    &:hover {
        color: $color-orange;
    }
}
