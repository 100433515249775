@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    padding: 16px 0;
    width: 100%;

    &.dashboard_page {
        background-color: $color-dark;
        color: white;
    }
}

.container {
    @extend .container;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;

    @include mobile {
        height: 32px;
    }
}

.logo {
    position: relative;
    display: flex;
    margin: 0 64px 0 0;
    color: $color-accent;
    transition: none;

    &::before {
        content: "";
        position: absolute;
        right: -32px;
        top: 50%;
        width: 1px;
        height: 40px;
        background-color: rgba($color-white, 0.12);
        transform: translateY(-50%);
        pointer-events: none;

        @include devices {
            display: none;
        }
    }

    svg path {
        transition: $trans-def;
    }

    .dashboard_page & {
        path:not([fill="currentColor"]) {
            fill: $color-white;
        }

        path[fill="currentColor"] {
            fill: $color-accent;
        }
    }

    .wrapper:not(.dashboard_page) &:hover {
        color: $color-dark;
    }

    .dashboard_page &:hover {
        path[fill="currentColor"] {
            fill: $color-white;
        }
    }

    @include tablet {
        margin: 0;
    }

    @include devices {
        margin-right: auto;

        &_small {
            color: $color-accent;
        }
    }
}

.nav {
    position: relative;
    margin-right: auto;

    &__link {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-decoration: none;
        color: $color-dark;
        transition: $trans-def;

        .dashboard_page & {
            color: $color-white;

            @include tablet {
                color: $color-dark;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -35px;
            border-radius: 2px;
            width: 100%;
            height: 2px;
            background-color: $color-accent;
            opacity: 0;
            transition: $trans-def;

            @include tablet {
                display: none;
            }
        }

        &:not(:last-child) {
            margin-right: 33px;

            @include mobile {
                margin: 0;

            }
        }

        &:hover {
            color: $color-accent;
        }

        .default_page & {
            @include desktop {
                margin-bottom: 28px;
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
            }
        }

        @include tablet {
            margin-bottom: 28px;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
        }

        &,
        .default_page & {
            @include mobile {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
                padding: 16px 0;
                border-top: 1px solid rgba($color-dark, 0.12);
            }
        }

        &:global(.active) {
            &::before {
                opacity: 1;

                @include desktop {
                    display: none;
                }
            }
        }
    }

    &__dropdown {
        position: relative;
        margin-right: 35px;

        @include mobile {
            margin: 0;
        }

        &_btn {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            text-decoration: none;
            color: $color-dark;

            .dashboard_page & {
                @include desktop-up {
                    color: $color-white;
                }
            }

            svg {
                transition: none;
                margin-left: 10px;
                width: 9px;
                height: 5px;
                fill: currentColor;

                @include desktop {
                    width: 13px;
                    height: 8px;
                }

                @include mobile {
                    margin: 0;
                    position: absolute;
                    right: 0;
                    top: 22px;
                    transform: rotate(-90deg);
                }
            }

            &:hover {
                color: $color-accent;
            }

            @include tablet {
                margin-bottom: 28px;
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
            }

            &,
            .default_page & {
                @include mobile {
                    font-size: 18px;
                    line-height: 22px;
                    padding: 16px 0;
                    margin: 0;
                    position: relative;
                    width: 100%;
                    justify-content: flex-start;
                }
            }

            &:global(.is-active) {
                color: $color-accent;

                svg {
                    transition: $trans-def;
                    transform: rotate(-180deg);
                    fill: currentColor;
                }
            }
        }
    }

    &__list {
        @include burger {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

/*        .dashboard_page & {
            flex-direction: inherit;
            height: initial;

            @include tablet {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }*/

    }

    &_desktop {
        //.wrapper:not(.dashboard_page) & {
        .wrapper & {
            @include burger {
                display: none;
            }
        }

        @include tablet {
            display: none;
        }

        .nav__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.become {
    &__link {
        margin-right: 32px;
        margin-left: 32px;
        min-width: 126px;
        font-weight: 500;
        color: $color-black;
        transition: all 0.3s ease;

        @include tablet {
            margin-right: 5px;
            margin-left: auto;
        }

        &:hover {
            color: $color-accent;
        }

        @include mobile {
            display: none;
        }
    }
}

.links {
    display: flex;

    li {
        font-size: 1.6rem;
        margin-right: 2em;
    }
}

.sign_in {
    &_small {
        &:global(.MuiButton-root) {
            padding-top: 10px;
            padding-bottom: 10px;
            height: 32px;

            @media (min-width: 640px) {
                display: none;
            }
        }
    }
}

.sign_up {
    margin-right: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    @include desktop {
        margin-right: 24px;
        margin-left: 0;
    }

    @include mobile {
        display: none;
    }
}

.burger {
    display: none;
    margin-left: 20px;

    g {
        opacity: 1;
    }

    rect {
        fill: $color-accent;
        opacity: 1;
    }

    @include burger {
        display: block;
    }

    .dashboard_page & {
        path {
            stroke: $color-white;
        }
    }
}

//search
.search {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 40px;
    width: 100%;
    max-width: 366px;

    @media (min-width: 1240px) and (max-width: 1366px) {
        margin-right: 24px;
        max-width: 290px;
    }

    &__input {
        width: 100%;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 9px 16px;
        padding-right: 150px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        background: $color-white;
        box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);
    }

    &__submit {
        position: absolute;
        top: 0;
        right: 112px;
        padding: 11px;
        border: none;
        color: $color-accent;
        background: none;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            right: -8px;
            top: 50%;
            width: 1px;
            height: 24px;
            background-color: rgba($color-dark, 0.12);
            transform: translateY(-50%);
            pointer-events: none;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        &:hover {
            color: $color-dark;
        }
    }

    &__type {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        padding: 12px 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        color: $color-dark;

        svg {
            transform: rotate(-90deg);
        }

        &_menu {
            svg {
                margin-right: 12px;
                transform: rotate(0deg);
            }
        }

        &_active {
            color: $color-accent;
        }
    }
}

.menus {
    display: flex;
    list-style: none;
}

.menu__items {
    position: relative;
    font-size: 14px;
    padding-top: 24px;

    //&:not(:last-child) {
    //margin-bottom: 24px;
    //}
}

.menu__items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu__items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;

    svg {
        margin-left: auto;
        width: 9px;
        height: 5px;
        //color: rgba($color-dark, 0.48);
        transform: rotate(-90deg);
        transition: $trans-def;
        fill: currentColor;
    }
}

.menu__items a,
.menu__items button {
    justify-content: flex-start;
    text-align: left;

    &:global(.is-active) {
        color: $color-accent;

        svg {
            color: $color-accent;
        }
    }
}

.menu__items a:hover,
.menu__items button:hover {
    color: $color-accent;

    svg {
        color: $color-accent;
    }
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute;
    left: 0;
    top: 52px;
    z-index: 15;
    display: none;
    list-style: none;
    border-radius: 8px;
    padding: 0 24px 24px;
    min-width: 286px;
    font-size: 16px;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.08), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);
    background: $color-white;

    .dashboard_page & {
        color: $color-dark;
    }
}

.dropdown.show {
    display: block;
}

.dropdown .dropdown__submenu {
    position: absolute;
    left: calc(100% + 24px);
    top: 0;
}

.profile {
    cursor: pointer;
}

.profile_icon {
    position: relative;
    display: flex;

    path {
        transition: $trans-def;
        fill: $color-accent;

        .dashboard_page & {
            fill: white;
        }
    }

    &:hover {
        path {
            fill: $color-dark;

            .dashboard_page & {
                fill: $color-accent;
            }
        }
    }

    @include mobile {
        height: 32px;
    }
}

.avatar {
    transition: $trans-def;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 50%;
        background: linear-gradient(0deg, rgba($color-light, 0.16), rgba($color-light, 0.16));
        opacity: 0;
        transition: $trans-def;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    @include mobile {
        width: 32px;
        height: 32px;
    }
}

.notification {
    margin-right: 22px;
    cursor: pointer;

    @include mobile {
        margin-right: 10px;
    }

    &__button {
        &:global(.MuiButton-root) {
            padding: 10px;
            width: 36px;
            height: 36px;
            min-width: 36px;
            border-color: transparent;
        }
        &:global(.MuiButton-text) {
            color: $color-light;
        }
        &:global(.MuiButton-contained) {
            background: rgba($color-light, 0.08);
        }
    }

    &__popup {
        margin-top: 10px;

        :global(.MuiPaper-root) {
            width: 360px;
            padding: 16px;
            z-index: 10;
        }

        &-list {
            margin-bottom: 20px;
        }

        &-buttons {
            display: flex;
        }

        &-setting {
            &:global(.MuiButton-root) {
                padding: 10px;
                min-width: 44px;
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            padding-bottom: 16px;
            border-bottom: 1px solid rgba($color-dark, 0.12);
            margin-bottom: 16px;
        }

        &_active {
            &::before {
                content: "";
                position: absolute;
                right: 0;
                top: calc(50% - 4px);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-accent;
                transform: translateY(-50%);
            }
        }

        &-media {
            margin-right: 12px;
        }

        &-content {
            padding-right: 8px;
        }

        &-text {
            margin-bottom: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }

        &-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba($color-dark, 0.64);
        }
    }
}

.chat {
    margin-right: 32px;
    width: 20px;
    height: 18px;

    &:hover {
        color: $color-accent;
    }

    @include mobile {
        margin-right: 20px;
    }

    &_is_notify {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: -2px;
            right: 0;
            height: 10px;
            width: 10px;
            background-color: $color-red;
            border-radius: 50%;
        }
    }
}

.wallet {
    display: flex;
    align-items: center;
    margin-right: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;

    svg {
        width: 20px;
        height: 16px;
        margin-right: 8px;
    }

    &:hover {
        color: $color-accent;
    }

    @include mobile {
        margin-right: 20px;
        font-size: 14px;
        line-height: 14px;
    }
}

.mobileMenu {
    position: relative;
    margin-top: 8px;
    padding: 0 20px;

    &__button {
        justify-content: flex-start;
        width: 100%;
        padding: 16px 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        text-decoration: none;
        color: $color-dark;

        svg {
            position: absolute;
            right: 0;
            top: 22px;
            width: 13px;
            height: 8px;
            fill: currentColor;
            transform: rotate(-90deg);
        }
    }

    &__list {
        &:not(:last-child) {
            border-bottom: 1px solid rgba($color-dark, 0.12);
        }
    }

    &__drop {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        padding:0 0 20px;
        background-color: $color-accent-reverse;
        min-height: calc(100vh - 108px);
        min-height: calc(var(--vh) - 108px);
        transition: $trans-def;
        display: none;

        &_level1 {
            top: -48px;
        }

        &_active {
            display: block;
        }
    }

    &__back {
        transition: $trans-def;
        margin-bottom: 8px;
        width: min-content;
        display: flex;
        align-items: center;
        color: $color-dark;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        &:active {
            color: $color-accent!important;
        }

        svg {
            margin-right: 12px;
        }
    }

    &__item {
        &:not(:last-child) {
            border-bottom: 1px solid rgba($color-dark, 0.12);
        }

        a {
            display: block;
            padding: 16px 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: $color-dark;

            &:global(.is-active) {
                color: $color-accent;

                svg {
                    color: $color-accent;
                }
            }

            svg {
                color: $color-accent;
            }
        }

    }
}
