@import "../.././../assets/styles/global/settings/variables";
@import "../.././../assets/styles/mixins/container";

.bar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 12px 32px;
    background-color: rgba($color-accent, 0.08);

    @include tablet {
        // justify-content: space-between;
        height: 64px;
        padding: 12px 20px;
    }

    @include mobile {
        // justify-content: space-between;
        height: 54px;
        padding: 12px 20px;
    }
}

.back {
    display: flex;
    align-items: center;
    width: 36px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $color-dark;
    max-width: 120px;

    svg {
        display: inline-block;
        width: 7px;
        height: 13px;

        path {
            transition: $trans-def;
        }
    }

    &:hover {
        color: $color-accent;

        path {
            stroke: $color-accent;
        }
    }

    @include mobile {
        font-size: 14px;
        line-height: 14px;
    }
}

.media {
    flex: 0 0 48px;
    margin-right: 12px;
    border-radius: 50%;
    max-width: 48px;

    img {
        object-fit: cover;
    }

    @include tablet {
        margin-right: 8px;
        margin-left: auto;
    }
}

.status {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    color: rgba($color-dark, 0.8);

    svg {
        margin-right: 5px;
        width: 6px;
        height: 6px;
    }

    &_online {
        svg {
            fill: $color-green;
        }
    }

    &_offline {
        svg {
            fill: $color-red;
        }
    }

    @include tablet {
        justify-content: center;
    }
}

.content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include tablet {
        flex-wrap: nowrap;
        text-align: center;
    }
}

.name {
    width: 100%;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;

    span {
        margin-left: 5px;
        letter-spacing: 0;
        color: rgba($color-dark, 0.8);
    }

    @include tablet {
        margin-bottom: 0;
        margin-right: 4px;
    }
}

.dots {
    position: relative;
    z-index: 1;
    margin-left: auto;

    &__button {
        width: 36px;
        height: 36px;
    }

    ul {
        margin: 0;
        padding: 8px 0;

        li {
            margin-bottom: 0;
            padding-left: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            svg {
                margin-right: 14px;
            }

            &::before {
                display: none;
            }
        }
    }
}

.link {
    cursor: pointer;
}

.info {
    margin: 8px 0;

    &__type {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #3e7eea;

        svg {
            margin-right: 8px;

            path {
                stroke: currentColor;
            }
        }

        &_session {
            color: #1cad70;
        }
    }

    &__title {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
    }
}
