$color-accent: #f66700; //246, 103, 0
$color-accent-reverse: #f9f5ee;

$color-orange: #ff7e21;
$color-bg: #FAF9F7;
$color-dark: #393e41; //(57, 62, 65)
$color-black: #262d3d;
$color-white: #fff;
$color-navy: #2c295d;
$color-green: #43d854;
$color-red: #e51748;
$color-light: #fff;
