@import "../.././assets/styles/global/settings/variables";

.wrapper {
    position: relative;
}

.tabs {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid rgba($color-dark, 0.12);
    }
}

.list {
    margin-top: 24px;
}

.layout {
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);
    background: $color-light;

    &_method {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__card {
        @include desktop-lg-up {
            display: flex;
        }
    }

    @include tablet {
        padding: 16px;

        &_method {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.balance {
    &__cards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include desktop-up {
            grid-gap: 24px 16px;
        }

        @include desktop-lg-up {
            grid-gap: 24px 16px;
            width: 448px;
        }

        @include tablet {
            width: 100%;
        }
    }

    &__card {
        width: calc(50% - 8px);
        border: 1px solid rgba($color-orange, 0.24);
        border-radius: 8px;
        padding: 16px;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: rgba($color-dark, 0.8);

        @include desktop-lg-up {
            width: 216px;
        }

        @include tablet {
            width: 100%;

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }

            &:last-of-type {
                margin-bottom: 24px;
            }
        }

        &_color {
            border-color: transparent;
            background: rgba($color-orange, 0.08);
        }

        &-sum {
            margin-top: 20px;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.01em;
            color: $color-orange;
        }
    }

    &__button {
        &:global(.MuiButton-root) {
            width: calc(50% - 8px);

            @include desktop-lg-up {
                width: 216px;
            }

            @include tablet {
                width: 100%;

                &:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    &__payment {
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid rgba($color-dark, 0.12);

        @include desktop-lg-up {
            margin-top: 0;
            margin-left: auto;
            padding-left: 32px;
            border-top: 0;
            border-left: 1px solid rgba($color-dark, 0.12);
        }

        &-label {
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
        }

        &-card {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            span {
                display: block;
                margin-left: 12px;
                margin-right: 155px;

                @include tablet {
                    margin-right: 50px;
                }
            }
        }

        &-edit {
            margin-left: auto !important;

            svg {
                transition: $trans-def;
            }

            &:hover {
                svg {
                    transform: scale(1.08);
                }
            }
        }
    }
}

.history {
    &__row {
        position: relative;
        display: flex;
        align-items: center;
        padding: 32px;

        &:not(:last-child) {
            &::before {
                content: "";
                position: absolute;
                left: 32px;
                right: 32px;
                bottom: 0;
                border-bottom: 1px solid rgba($color-dark, 0.12);

                @include tablet {
                    left: 16px;
                    right: 16px;
                }
            }
        }

        @include tablet {
            flex-wrap: wrap;
            padding: 16px;
            padding-bottom: 0;
        }
    }

    &__col {
        width: 100%;

        &-download {
            width: 25%;
            text-align: center;
        }

        @include tablet {
            margin-bottom: 16px;
            width: 50%;
            text-align: left;
        }
    }

    &__head {
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: rgba($color-dark, 0.8);

        .history__row {
            padding-top: 16px;
            padding-bottom: 16px;

            &::before {
                content: none;
                display: none;
            }
        }
    }

    &__body {
        border-radius: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $color-dark;
        background-color: $color-light;
    }

    &__download {
        svg {
            transition: $trans-def;
        }

        &:hover {
            svg {
                transform: scale(1.08);
            }
        }
    }
}

.method {
    &__item {
        &:before {
            right: 0 !important;
            left: 0 !important;
        }

        &-check {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            width: 20px;
            height: 20px;
            border: 1px solid $color-accent;
            box-sizing: border-box;
            border-radius: 50%;
            text-align: center;

            svg {
                display: none;
            }

            path {
                fill: white;
            }

            @include mobile {
                padding-top: 2px;
            }
        }

        &-add {
            svg {
                margin-right: 25px;
            }
        }

        &:global(.MuiAccordion-root.Mui-expanded) {
            margin: 0;

            &:last-child {
                padding-bottom: 32px !important;
            }
        }

        &:global(.Mui-expanded) {
            background-color: transparent !important;

            .method__item-check {
                background-color: $color-accent;

                svg {
                    display: block;
                }
            }
        }

        :global .MuiAccordionSummary-root {
            align-items: center;
            padding-right: 0;
            padding-left: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
        }

        :global .MuiAccordionDetails-root {
            max-width: 372px;
            padding: 0;

            @include tablet {
                max-width: 100%;
            }
        }
    }
}
