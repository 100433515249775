@import "../../assets/styles/global/settings/variables";
@import "../../assets/styles/mixins/container";


.container {
    --top-menu-height: 68px;
    @extend .container;

    box-sizing: border-box;
    margin-top: 72px;
    padding: 48px 64px 24px;
    min-height: calc(100vh - 72px);
    min-height: calc(var(--vh) - 72px);
    display: flex;
    background-color: $color-bg;

    &::before {
        content: "";
        position: absolute;
        top: -128px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: $color-bg;
    }

    @include tablet {
        margin-top: 68px;
        padding: var(--container-padding-size);
        min-height: calc(100vh - 68px);
        min-height: calc(var(--vh) - 68px);
        flex-direction: column;
    }
}

.section {
    padding: 32px;
    box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 16px;
    background-color: white;

    & + & {
        margin-top: 16px;
    }

    @include tablet {
        padding: 16px;
        border-radius: 12px;
    }

    &_accordion {
        @include tablet {
            position: relative;
            padding: 3px 20px 3px 16px;
            box-shadow: none;
            border-radius: 0;

            &:first-of-type {
                border-radius: 12px 12px 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 12px 12px;
            }

            & + & {
                margin: 0;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: calc(100% - 16px * 2);
                    border-top: 1px solid rgba($color-dark, 0.12);
                }
            }
        }
    }
}

.section_container {
    display: flex;
    justify-content: space-between;
}

.sidebar {
    width: 272px;

    @include desktop {
        width: 242px;
    }

    &.cabinet {
        margin-right: 64px;

        @include desktop {
            margin-right: 32px;
        }
    }

    &__top {
        display: flex;
        width: 100%;

        &-icon {
            margin-right: 12px;
        }

        &-button {
            &:global(.MuiButtonBase-root) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }

    @include tablet {
        width: 100%;

        &:not(.sidebar_visible) {
            display: none;
        }
    }
}

.content {
    width: calc(100% - 336px);

    @include desktop {
        width: calc(100% - 274px);
    }

    &_fullWidth {
        padding: 0;
        width: 100%;
    }

    @include tablet {
        padding: 0;
        width: 100%;
    }
}

.join {
    position: relative;
    margin-top: 48px;
    margin-bottom: 16px;
    padding: 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    &__title {
        margin-bottom: 16px !important;
        text-align: center;
    }
}

a.back {
    display: block;
    margin: 10px 0 34px;
    color: $color-dark;
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;

    svg {
        margin-right: 14px;

        path {
            transition: $trans-def;
        }
    }

    &:hover {
        color: $color-accent;
        text-decoration: none;

        path {
            stroke: $color-accent;
        }
    }

    @include mobile {
        margin: 0 0 20px;
    }
}

.field_title {
    margin-bottom: 12px;
    font-size: 13px;
    line-height: 1em;
}


.radio_button {
    transition: $trans-def;
    position: relative;
    margin: 0 !important;
    padding-left: 52px;
    height: 48px;
    min-width: 177px;
    border: 1px solid rgba($color-dark, 0.12);
    border-radius: 8px;
    box-sizing: border-box;

    span {
        transition: $trans-def;
        color: $color-dark;
    }

    &:not(:first-child) {
        margin-left: 16px !important;
    }

    @include mobile {
        width: 100%;
    }

    :global(.MuiFormControlLabel-label) {
        &::before {
            content: '';
            position: absolute;
            left: 16px;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            border: 1px solid rgba($color-dark, 0.18);
            z-index: 0;
        }
    }

    &.active {
        background-color: rgba($color-accent, 0.08);
        border-color: transparent;

        :global(.MuiFormControlLabel-label) {
            &::before {
                content: '';
                position: absolute;
                left: 16px;
                width: 24px;
                height: 24px;
                background-color: $color-accent;
                border-radius: 100%;
                border-color: transparent;
                box-sizing: border-box;
                z-index: 0;
            }

            &::after {
                position: absolute;
                left: 23px;

                content: url("data:image/svg+xml, %3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 4.5L3.5 6.5L8.5 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                z-index: 1;
            }
        }
    }

    &:hover {
        background-color: rgba($color-accent, 0.08);
        border-color: transparent;
    }

    &__radio {
        display: none !important;
        position: static;
        margin-right: 18px;
        width: 20px;
        height: 20px;
        padding: 0;
        color: $color-accent;
        transition: $trans-def;

        div::before {
            transition: $trans-def;
        }
    }
}

.input_box_half_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input_box {
    width: 100%;

    &:not(:last-of-type) {
        margin-bottom: 16px !important;
    }

    :global(.MuiAutocomplete-popupIndicator) svg {
        fill: $color-accent;
    }

    &_half {
        width: calc(50% - 8px) !important;

        @include mobile {
            width: 100% !important;
        }
    }
}

.help {
    position: relative;
    display: block;
    border: 1px solid rgba($color-accent, 0.24);
    border-radius: 16px;
    padding: 32px 32px 32px 60px;

    &:hover {
        border-color: $color-accent;
    }

    &__icon {
        position: absolute;
        top: 35px;
        left: 32px;
        width: 16px;
        height: 16px;
    }

    &__title {
        margin-bottom: 8px !important;
        font-size: 18px;
        line-height: 22px;
    }

    &__text {
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.64);
    }
}

.sign_up {
    position: relative;
    margin: 0 auto 120px;
    border-radius: 16px;
    padding: 56px 20px;
    text-align: center;
    background: $color-accent;

    @include tablet {
        margin: 0 auto 48px;
        padding: 32px 20px;
    }

    @include mobile {
        margin: 0 auto 40px;
        padding: 40px 20px;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: $color-white;

        span {
            margin-right: 32px;
        }

        @include mobile {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__btn {
        min-width: 216px !important;
        margin: 0 auto;

        @include mobile {
            min-width: 100% !important;
            width: 100%;
        }

        &:global(.MuiButton-root) {
            &:hover {
                background: rgba($color-white, 0.9) !important;
            }
        }
    }
}

.popup {
    &-title {
        margin: 0;
        margin-bottom: 48px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > * {
            width: calc(50% - 8px);
        }
    }
}
