@import "../../assets/styles/global/settings/colors";
@import "../../assets/styles/global/settings/variables";
@import "../../assets/styles/global/base/animations";
@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/mixins/container";

.contact {
    position: relative;
    min-height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: -128px;
        left: 0;
        z-index: -1;
        width: 1225px;
        height: 582px;
        background: $color-bg;
        border-radius: 0 0 0 24px;

        @include tablet {
            border-radius: 0;
        }
    }

    @include mobile {
        background-color: $color-bg;
    }

    &__container {
        @extend .container;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 120px;

        &::before {
            content: '';
            position: absolute;
            top: -128px;
            left: 0;
            z-index: -1;
            border-bottom-right-radius: 40px;
            width: 1225px;
            height: 582px;
            background: $color-bg;

            @include tablet {
                height: 595px;
            }
        }

        @include tablet {
            flex-direction: column;
            padding-bottom: 48px;
        }
    }

    &__body {
        margin-right: 50px;
        padding-top: 64px;

        @include tablet {
            margin:0 auto 16px;
        }

        @include mobile {
            padding-top: 24px;
        }
    }

    &__pretitle {
        margin-bottom: 16px;
        max-width: 416px;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $color-accent;

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 12px;
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 32px;
        width: 416px;
        font-weight: 700;
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.01em;
        color: $color-dark;

        span {
            color: $color-accent;
        }

        @include tablet {
            text-align: center;
        }

        @include mobile {
            margin-bottom: 18px;
            width: 100%;
            font-size: 32px;
            line-height: 36px;
        }
    }

    &__text {
        margin-bottom: 32px;
        max-width: 416px;
        font-size: 20px;
        line-height: 28px;
        color: $color-dark;
        opacity: 0.8;

        @include tablet {
            text-align: center;
        }

        @include mobile {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__link {
        margin-top: 16px;
        display: flex;
        align-items: center;
        border-radius: 16px;
        padding: 30px 35px;
        background: $color-white;
        box-shadow: 0 16px 24px rgba(0, 20, 91, 0.04), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        svg {
            margin-right: 24px;
            width: 20px;
            height: 20px;
            color: $color-orange;

            @include tablet {
                margin-right: 16px;
            }
        }

        a {
            color: $color-dark;

            &:hover {
                color: $color-orange;
            }
        }

        @include tablet {
            width: 100%;
            padding: 20px 16px;
            border-radius: 8px;
            font-size: 16px;
            line-height: 16px;
        }
    }

    &__form {
        max-width: 752px;
        padding: 56px;
        border-radius: 24px;
        box-shadow: 0 12px 24px rgba(0, 20, 91, 0.06), 0 0 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
        background: $color-white;

        :global(.MuiFormControl-root) {
            margin-bottom: 16px;
        }

        @include tablet {
            padding: 20px;
            width: 100%;
            max-width: 100%;
            border-radius: 12px;
        }

        @include mobile {
            padding: 20px 16px;
        }

        &_bottom {
            display: flex;
            align-items: center;

            @include desktop-sm {
                flex-direction: column-reverse;
                align-items: flex-start;
            }
        }
    }

    &__button {
        width: 192px !important;

        @include desktop-sm {
            width: 100% !important;
        }
    }
}

.terms {
    font-size: 16px;
    font-weight: 300;
    color: rgba($color-dark, 0.48);
    line-height: 1.3em;

    @include desktop-sm {
        margin-top: 16px;
    }

    @include mobile {
        font-size: 14px;
    }

    &__link {
        color: $color-accent;
        text-decoration: underline;
        transition: $trans-def;
    }
}
