@import "../../assets/styles/global/settings/variables";


.chat {
    min-height: 420px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
}

