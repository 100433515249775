@import "../../assets/styles/global/settings/variables";


.tip {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 14px;

    svg {
        cursor: pointer;
    }

    path {
        transition: $trans-def;
        fill: rgba($color-dark, 0.40);
    }

    &:hover {
        path {
            fill: rgba($color-dark, 0.64);
        }
    }
}
