@import '../../../assets/styles/global/settings/variables';


.block {
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 32px;

    @include mobile {
        padding: 16px;
        border-radius: 12px;
        margin-bottom: 12px;
    }

    > div {
        max-width: 370px;

        > div:not(:first-child) {
            padding-top: 16px;
        }
    }
}

.tabs {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid rgba($color-dark, 0.12);
    }
}

.list {
    padding-top: 24px;

    @include mobile {
        padding-top: 20px;
    }

    h4 {
        margin-bottom: 32px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    p {
        max-width: 525px;
        color: $color-dark;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    button {

        @include mobile {
            width: 100%;
        }
    }

    .button_eye svg {
        width: 20px;
        height: 20px;

        path {
            fill: #f66700;
        }
    }
}

.notification {
    max-width: 100% !important;

    label {
        margin-bottom: 16px;
        width: 100%;
        color: $color-dark;

        &:last-child {
            margin-bottom: 0;
        }

        @include mobile {
            align-items: flex-start;
        }

        > span:first-child {

            @include mobile {
                margin-top: 4px;
            }
        }
    }
}

.notification:first-child {
    margin-bottom: 40px;

    @include mobile {
        margin-bottom: 32px;
    }
}

.notification h4 {
    margin-bottom: 24px;

    @include mobile {
        margin-bottom: 20px;
    }
}
