@import "../.././assets/styles/global/settings/variables";


.filters {
    position: relative;
    margin-top: 16px;
    margin-bottom: 48px;
    padding: 32px;
    border-radius: 16px;
    background: $color-white;
    box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);

    @include tablet {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
    }

    @include mobile {
        padding: 16px;
    }

    &__bottom {
        margin-top: 32px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;

        @include tablet {
            margin: 0;
        }
    }

    &__reset {
        margin-left: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: right;
        color: rgba($color-dark, 0.8);

        span {
            white-space: nowrap;
        }

        svg {
            flex: 0 0 auto;
            margin-right: 8px;
        }

        &:hover {
            color: $color-orange;
        }

        @include tablet {
            margin-left: 24px;
        }
    }

    &__more {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: rgba($color-dark, 0.8);

        svg {
            flex: 0 0 auto;
            margin-left: 16px;
            fill: $color-accent;
            transition: $trans-def;
        }

        &:global(.is-active) {
            svg {
                transform: scale(1, -1);
            }
        }

        &:hover {
            color: $color-orange;
        }
    }

    &__control {
        position: relative;
        border-radius: 8px;
        padding: 8px;
        width: 100%;
        height: 56px;
        border: 1px solid rgba($color-dark, 0.18);

        label {
            transform: translate(0, -6px) scale(0.81);
        }
    }

    &__slider {
        position: relative;
        z-index: 10;
        height: 10px;

        :global(.MuiSlider-root) {
            position: absolute;
            bottom: 0;
            left: 12px;
            width: calc(100% - 24px);
        }
    }

    &__input {
        &:global(.MuiInputBase-root) {
            position: absolute;
            left: 8px;
            bottom: 0;
            display: flex;
            align-items: center;
            border: 0;
            width: max-content;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            background-color: transparent;
            z-index: 0;

            @include mobile {
                bottom: 4px;
            }

            &::before,
            &::after {
                display: none;
                content: none;
            }

            &:last-of-type {
                left: auto;
                right: -8px;

                @include mobile {
                    right: -6px;
                }
            }

            input {
                z-index: 0;
                padding: 0;
                border: 0;
                height: 40px;
                border-radius: 0;
                max-width: 40px;
                background-color: transparent;
            }
        }
    }

    &__sorting_by {
        :global(.MuiSelect-select) {
            padding: 0;
            border: 0;
            color: rgba($color-dark, 0.64);
        }

        svg {
            top: calc(50% - 4px);
            fill: rgba($color-dark, 0.64);
        }

        fieldset {
            border: none;
        }
    }
}

.input__box {
    :global(.MuiAutocomplete-popupIndicator) svg {
        fill: $color-accent;
    }
}

.first_session_free {
    :global(.MuiOutlinedInput-root fieldset legend) {
        display: inherit;
    }
}

.close_popup {
    display: block;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.radio_button {
    @include mobile {
        width: calc(50% - 8px);
        min-width: inherit;
    }

    &:global(.button-active):hover {
        opacity: 0.8;
    }
}

.filters_button {
    margin: 24px 0 32px !important;

    svg {
        margin-right: 16px;
    }
}

.mobile_reset {
    @include tablet {
        margin-bottom: 24px;
        padding-top: 0;
        display: flex;
        align-items: center;
    }
}

.mobile_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $color-dark;
}

.is_filter {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: -7px;
        right: -9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-accent;
    }
}
