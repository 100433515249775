@import "../../assets/styles/global/settings/variables";


.alert {
    display: flex;
    position: fixed;
    top: calc(var(--headerHeight) + 24px);
    right: var(--container-padding-size);
    padding: 15px 14px 15px 18px;
    width: 320px;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;

    background-color: $color-accent;
    color: $color-light;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.3);
    z-index: 1301;

    @include mobile {
        width: calc(100% - var(--container-padding-size) * 2);
    }

    &__info {
        display: flex;
        align-items: center;

        a {
            color: currentColor;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        > svg {
            margin-right: 10px;
        }
    }

    svg {
        flex-shrink: 0;
    }

    &__close {
        padding: 2px 4px;
        box-sizing: content-box;
        width: 10px;
        height: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.15);
        }
    }

    &__error {
        background-color: $color-red;
    }
}
