@import "../../assets/styles/global/settings/variables";

.container {
    position: relative;
    width: 100%;
    height: 22px;
    display: flex;
}

.line {
    height: 1px;
    width: 100%;
    margin: auto;
    background-color: rgba($color-dark, 0.18);
}

.title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 32px;
    background-color: white;
    font-size: 16px;
    line-height: 22px;
    color: $color-dark;

    @include mobile {
        padding: 0 24px;
    }
}
