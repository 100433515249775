@import "../../assets/styles/mixins/breakpoints";
@import "../../assets/styles/mixins/container";
@import "../../assets/styles/global/settings/variables";
@import '../../assets/styles/extends/section';

.back_to_topic {
    display: flex;
    align-items: center;
    margin-bottom: 56px;
    font-weight: 600;
    font-size: 16px;
    color: $color-dark;
    max-width: 120px;

    svg {
        display: inline-block;
        margin-right: 12px;
        color: $color-accent;
    }

    &:hover {
        color: $color-accent;
    }

    @include mobile {
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 43px;
    }
}

.main {
    @extend .container;
}

.questions {
    //padding-top: 16px;
    margin-top: -16px;

    @include tablet {
        padding: 48px 0 0 0;
        margin: 0;
    }

    @include mobile {
        padding: 28px 0 0 0;
    }

    &__header {
        @extend .container;

        text-align: center;
    }

    &__title {
        @extend .section_title;

        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 12px;
        }
    }

    &__titlePopular {
        margin: 0;
        margin-bottom: 56px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
        text-align: center;
        color: $color-dark;

        span {
            color: $color-accent;
        }
    }

    &__subtitle {
        @extend .section_subtitle;
        text-transform: uppercase;
    }

    &__list {
        margin: 24px auto 56px;
        max-width: 928px;
        min-height: calc(100vh - 658px);
        min-height: calc(var(--vh) - 658px);

        @include tablet {
            max-width: 100%;
            margin: 30px auto 24px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        color: $color-accent;
        background-color: rgba($color-accent, 0.08);
        transition: $trans-def;

        :global(.Mui-expanded) & {
            color: $color-white;
            background-color: $color-accent;
        }
    }
}
