@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";

.hero_container {
    font-family: $font-family-base;
    color: $color-dark;

    @include mobile {
        font-size: 40px;
        line-height: 36px;
    }
}

.content__title {
    &_de {
        @include mobile {
            font-size: 32px !important;
        }
    }
}

.date {
    margin-top: -40px;
    opacity: 0.64;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 56px;

    @include mobile {
        margin-top: -32px;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 48px;

    }
}

.main {
    @extend .container;

    padding-bottom: 56px;
    font-family: $font-family-base;
    color: $color-dark;

    @include tablet {
        padding-bottom: $tablet_padding_vertical;
        font-size: 16px;
    }

    @include mobile {
        padding-top: 24px;
        padding-bottom: 36px;
    }
}

.main_container {
    margin-top: 56px;
    width: 769px;

    @include tablet {
        margin-top: 44px;
        width: 100%;
    }
}

.table {
    margin-top: 110px;
    overflow-x: scroll;
    scrollbar-width: none;


    &::-webkit-scrollbar {
        display: none;
    }

    @include tablet {
        margin-top: 44px;
    }
}
