@import "../.././assets/styles/global/settings/variables";

.wrapper {
    position: relative;
    display: flex;
    min-height: 100%;

    @include tablet {
        flex-direction: column-reverse;
    }
}

.sidebar {
    width: 412px;

    &__top {
        display: flex;
        width: 100%;
        gap: 10px;

        &-icon {
            margin-right: 12px;
        }

        &-button {
            &:global(.MuiButtonBase-root) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }

    @include tablet {
        width: 100%;
    }
}

.content {
    position: relative;
    margin-right: 32px;
    width: calc(100% - 412px);
    min-height: 100%;

    @include tablet {
        padding: 0;
        width: 100%;
    }
}

.chat {
    position: relative;
    padding-bottom: 100px;
    padding-left: 32px;
    height: calc(100% - 80px - 44px);
    border-radius: 0 0 16px 16px;
    background: $color-white;
    box-shadow: 0px 16px 24px rgba(0, 20, 91, 0.02), 0px 2px 6px rgba(0, 20, 91, 0.02), 0px 0px 1px rgba(0, 20, 91, 0.02);

    @include mobile {
        padding-left: 0;
    }
}

.scroller {
    position: relative;
    // height: 100%;
    // overflow: hidden;
    // overflow-y: auto;
}

.view {
    padding-right: 32px;

    @include tablet {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.toolbar {
    display: flex;
    align-items: center;
    border-radius: 16px 16px 0 0;
    width: 100%;
    height: 80px;
    padding: 16px 32px;
    background-color: rgba($color-accent, 0.08);

    @include mobile {
        display: block;
        padding: 16px;
        height: auto;
    }
}

.infobar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color-dark, 0.72);

    @include tablet {
        padding: 16px;
    }

    @include mobile {
        display: block;
        padding: 8px;
    }

    &__price {
        display: flex;
        align-items: center;
        width: 50%;

        @include mobile {
            width: 100%;
        }

        &-sum {
            margin-right: 5px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: $color-accent;
        }
    }

    &__project {
        display: flex;
        align-items: center;
        width: 50%;

        @include mobile {
            width: 100%;
            margin-top: 8px;
        }

        &-link {
            margin-left: 5px;
            color: $color-accent;

            &:hover {
                color: $color-orange;
            }
        }
    }
}

.user {
    display: flex;
    align-items: center;
    width: 50%;

    @include mobile {
        width: 100%;
    }

    &:last-child {
        flex-direction: row-reverse;
        margin-left: auto;
        text-align: right;

        .user__media {
            margin-right: 0;
            margin-left: 12px;
        }

        @include mobile {
            margin-top: 8px;
        }
    }

    &__media {
        flex: 0 0 48px;
        margin-right: 12px;
        border-radius: 50%;
        max-width: 48px;

        img {
            object-fit: cover;
        }
    }

    &__status {
        :global(.MuiBadge-badge) {
            border: 1px solid $color-white;
        }

        &_online {
            :global(.MuiBadge-badge) {
                background-color: $color-green;
            }
        }

        &_offline {
            :global(.MuiBadge-badge) {
                background-color: $color-red;
            }
        }
    }

    &__name {
        width: 100%;
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
    }

    &__info {
        width: 100%;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color-dark, 0.72);

        span {
            margin-left: 5px;
        }
    }
}

.timer {
    margin-top: 24px;
    padding: 32px 48px;
    border-radius: 16px;
    background-color: rgba($color-accent, 0.12);

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
    }

    &__time {
        margin: 40px auto;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.01em;
        color: $color-accent;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__start {
        margin-bottom: 16px !important;
    }
}

.controls {
    margin-top: 32px;
}

.disabling_record {
    visibility: hidden;
    margin-bottom: 2px;
    height: 16px;
    line-height: 1em;

    &_active {
        visibility: visible;
    }
}

.checkbox {
    margin-bottom: 24px;
}

.link {
    color: $color-orange;

    &:hover {
        color: $color-dark;
    }
}

.messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
}

.input__box {
    :global(.MuiAutocomplete-popupIndicator) svg {
        fill: $color-accent;
    }
}

.first_session_free {
    :global(.MuiOutlinedInput-root) {
        & fieldset legend {
            display: inherit;
        }

        padding-top: 10px!important;
    }

    :global(.MuiAutocomplete-endAdornment) {
        top: calc(50% - 9px) !important;
    }

}
