// Settings
$desktop-xl: 2000;
$desktop-lg: 1919;
$desktop: 1439;
$desktop-sm: 1349;
$burger: 1240; // only for burger visibility
$tablet: 1023;
$mobile: 639;
$mobile-landscape: 819;
$mobile-landscape-sm: 639;
$mobile-lg: 767;



@mixin desktop-up {
	@media (min-width: (($tablet + 1) * 1px)) {
		@content;
	}
}

@mixin desktop-xl {
    @media (min-width: ($desktop-xl * 1px)) {
        @content;
	}
}

@mixin desktop-lg-up {
    @media (min-width: (($desktop + 1) * 1px)) {
        @content;
    }
}

@mixin desktop-lg {
	@media (max-width: ($desktop-lg * 1px)) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: ($desktop * 1px)) {
		@content;
	}
}

@mixin burger {
	@media (max-width: ($burger * 1px)) {
		@content;
	}
}

@mixin desktop-sm {
	@media (max-width: ($desktop-sm * 1px)) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: ($tablet * 1px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: ($mobile* 1px)) {
		@content;
	}
}

@mixin mobile-lg {
	@media (max-width: ($mobile-lg * 1px)) {
		@content;
	}
}

@mixin mobile-landscape {
	@media (max-width: ($mobile-landscape* 1px)) and (orientation: landscape) {
		@content;
	}
}

@mixin mobile-landscape-sm {
	@media (max-width: ($mobile-landscape-sm* 1px)) and (orientation: landscape) {
		@content;
	}
}

@mixin mobile-xs {
	@media (max-width: (340 * 1px)) {
		@content;
	}
}

@mixin devices {
	@media (max-width: (($desktop - 1) * 1px)) {
		@content;
	}
}
