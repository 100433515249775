@import "../../assets/styles/global/settings/variables";


.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

h4 {
    margin-top: 0;
    width: 100%;
}

.title_profile_mobile,
.title_mobile {
    display: none;

    @include tablet {
        display: block;
        margin-bottom: 24px !important;
    }
}

.title_profile_desktop {
    @include desktop {
        display: none;
    }
}

.title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include tablet {
        display: block;
    }
}

.title_profile {
    margin-left: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none !important;
    color: $color-dark !important;
    transition: $trans-def;

    &:hover {
        color: $color-orange !important;
    }
}

.title_desc {
    margin-bottom: 0 !important;

    @include tablet {
        display: none;
    }
}

.avatar_box {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
        margin-bottom: 12px;
        padding: 16px;
    }
}

.sticky_box {
    position: sticky;
    top: 16px;
    margin-top: 16px;

    @include tablet {
        position: initial;
    }
}

.progress_box {
    border: 1px $color-accent solid;

    @include tablet {
        margin-bottom: 16px;
    }

    h5 {
        margin-bottom: 16px;

        @include mobile {
            margin-bottom: 12px;
        }
    }
}

.progress_desc {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.64;
}

.with_border {
    border: 1px $color-accent solid;

    @include tablet {
        margin-bottom: 16px;
    }
}

.side_section_title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    & *:first-child {
        margin-right: 10px;
    }
}

.side_section_text {
    margin: 16px 0 20px 28px;
    font-weight: 400;
}

.upgrade_plan {
    margin-bottom: 24px;
}

.upgrade_title {
    font-size: 24px;
    line-height: 28px;
    color: $color-accent
}

.current_plan {
    margin-top: 2px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: $color-dark
}

.input_box {
    margin-bottom: 16px !important;
    width: calc(50% - 8px) !important;

    :global(.MuiAutocomplete-popupIndicator) svg {
        fill: $color-accent;
    }

    @include tablet {
        width: 100% !important;
    }

    &.input_box_large {
        width: 100% !important;
    }
}

.custom_ware {
    margin: -12px 0 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > *:first-child {
        width: inherit;
        margin-right: 16px;
    }
}

.array {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    &__main {
        width: calc(100% - 36px);

        &_row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__delete {
        position: relative;
        border-right: 1px dashed rgba($color-dark, 0.24);
        margin: 44px 9px 16px;

        .bin {
            position: absolute;
            right: -9px;
            top: -24px;
            cursor: pointer;

            path {
                transition: $trans-def;
            }

            &:hover path {
                stroke: $color-dark;
            }
        }
    }

    &__add {
        width: 100%;
        margin-bottom: 40px;
        color: $color-accent;
        font-weight: 600;
        line-height: 16px;

        > * {
            cursor: pointer;
            transition: $trans-def;

            &:hover {
                color: $color-orange;
            }
        }
    }
}

.employment_date {
    :global(.MuiInputBase-adornedEnd) {
        padding-right: 0;

/*        path {
            transition: $trans-def;
        }

        &:hover path {
            stroke: $color-dark;
        }*/
    }
    :global(.MuiInputBase-inputAdornedEnd) {
        z-index: 1;
    }
    :global(.MuiInputAdornment-root) {
        position: absolute;
        right: 14px;
    }
}

.save {
    width: 216px;

    @include tablet {
        margin-bottom: 32px !important;
    }

    @include mobile {
        width: 100%;
    }
}

.upload {
    margin: 24px 0 12px !important;
    width: 100%;
    cursor: pointer;

    &:hover {
        button {
            pointer-events: none;
            border-color: $color-accent;
        }
    }

    @include mobile {
        margin-top: 16px !important;
    }
}

.upload_text {
    font-size: 14px;
    line-height: 1em;
    color: rgba($color-dark, 0.64)
}

.upload_error {
    margin-top: 7px;
}

.documents {
    position: relative;
    width: 100%;
}

.year {
    width: 74px !important;
    min-width: 74px;
    margin-left: 16px !important;
}

.add_to_year {
    width: calc(100% - 74px - 16px) !important;
}

.documents_withYear {
    display: flex;
}

.attach {
    position: absolute;
    top: 15px;
    right: 16px;
    padding: 3px 4px 0;
    background-color: white;

    svg {
        cursor: pointer;
    }

    path {
        transition: $trans-def;
    }

    svg:hover {
        path {
            stroke: $color-dark;
        }
    }

    .documents_withYear & {
        right: 106px;
    }
}

.file_bar {
    display: flex;
    justify-content: space-between;
    width: 46px;
}

.still_work {
    margin-left: 50% !important;
    margin-top: -8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    @include mobile {
        margin-left: 0 !important;
    }
}

.switcher {
    margin: 8px 0 32px;
    > span {
        font-weight: 400;
    }
}

.switcher_last {
    margin-bottom: 40px;
}

.details_item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1em;
    font-weight: 400;

    &:not(:last-of-type) {
        margin-bottom: 20px;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    @include mobile {
        font-size: 14px;
    }

    a {
        color: $color-dark;

        &:hover {
            text-decoration: none;
            color: $color-accent;
        }
    }

    &_check {
        margin-right: 12px;
        width: 20px;
        height: 20px;
        border: 1px solid $color-accent;
        box-sizing: border-box;
        border-radius: 50%;
        text-align: center;

        path {
            fill: white;
        }

        @include mobile {
            padding-top: 2px;
        }
    }

    &_active .details_item_check {
        background-color: $color-accent;
    }
}

.required {
    text-transform: lowercase;
    color: rgba($color-dark, 0.5);
}

.add_options {
    margin: -14px 0 8px;
    width: 100%;
    text-align: right;

    .target {
        cursor: pointer;
        transition: $trans-fast;
        color: $color-accent;
        font-weight: 600;
        line-height: 16px;

        &_active {
            //color: $color-orange;
            visibility: hidden;
        }

        &:hover {
            color: $color-orange;

            &.target_active {
                //color: $color-accent;
            }
        }
    }
}

.radio_button {
    transition: $trans-def;
    position: relative;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
    height: 48px;
    border: 1px solid rgba($color-dark, 0.12);
    border-radius: 8px;

    &.checked_type {
        background-color: $color-accent;
        border-color: $color-accent;

        :global(.MuiFormControlLabel-label)::before {
            content: url("data:image/svg+xml, %3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 4.5L3.5 6.5L8.5 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            margin-right: 9px;
        }
    }

    &:hover {
        background-color: $color-accent;
        border-color: $color-accent;

        :global(.MuiFormControlLabel-label) {
            color: white;
        }
    }
}

.sidebar {
    width: 304px;

    @include tablet {
        width: 100%;
    }
}

.content {
    padding-left: 32px;
    width: calc(100% - 304px);

    &_fullWidth {
        padding: 0;
        width: 100%;
    }

    @include tablet {
        padding: 0;
        width: 100%;
    }
}
