@import '../../assets/styles/global/settings/variables';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    height: 100%;
    box-shadow: 0 12px 24px rgba(0, 20, 91, 0.06), 0 0 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 16px;
    background-color: $color-white;
    transition: $trans-fast;

    @include tablet {
        margin-bottom: 24px;
    }

    @include mobile {
        padding: 24px 16px 24px 16px;
    }

    &:last-child{

        @include tablet {
            margin-bottom: 0px;
        }
    }
}

.header {
    display: flex;
    text-align: left;
    margin-bottom: 48px;

    @include mobile {
        margin-bottom: 24px;
    }

    &__icon {
        margin-right: 18px;
    }
}

.title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $color-dark;

    &:not(:last-child) {
        margin-bottom: 6px;
    }

    @include mobile {
        font-size: 18px;
        font-weight: 600;
    }
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba($color-dark, 0.64);

    @include mobile {
        font-size: 14px;
        line-height: 14px;
    }
}

.popular {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 65px;
    height: 24px;
    border: 1px solid rgba($color-accent, 0.4);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $color-accent;
}

.price {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba($color-dark, 0.12);
    font-weight: 400;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.01em;
    color: $color-dark;

    @include desktop-sm {
        font-size: 55px;
    }

    @include mobile {
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 24px;
        padding-bottom: 24px;
    }

    &__number {
        color: $color-dark;

        span {
            color: rgba($color-dark, 0.32);
        }
    }

    &__per {
        margin-left: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-dark;

        span {
            color: rgba($color-dark, 0.32);
        }
    }

    &__text {
        span {
            color: $color-accent;
        }
    }
}

.includes {
    margin-bottom: 48px;

    @include mobile {
        margin-bottom: 24px;
    }

    &__title {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: left;
        color: $color-dark;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &__list {
        text-align: left;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: rgba($color-dark, 0.64);

        @include mobile {
            font-size: 14px;
            line-height: 14px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;

            @include mobile {
                margin-bottom: 20px;
            }
        }

        svg {
            margin-right: 16px;
            width: 12px;
            height: 12px;
            color: $color-accent;

            @include mobile {
                margin-right: 12px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.button {
    margin-top: auto !important;
}
