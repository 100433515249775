@import "../../assets/styles/global/settings/variables";


.progress_visual {
    margin: 12px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress_bar {
    position: relative;
    width: 80%;
    height: 6px;

    @include mobile {
        height: 6px;
    }

    &_static, &_dynamic {
        border-radius: 4px;
        width: 100%;
        height: 100%;
    }

    &_static {
        position: absolute;
        background-color: rgba($color-accent, 0.12);
    }

    &_dynamic {
        width: 0;
        background-color: $color-accent;
    }
}

.progress_percent {
    padding-left: 16px;
    color: $color-accent;
    font-size: 18px;
    line-height: 1em;
    font-weight: 600;

    @include mobile {
        margin-bottom: 0;
        font-size: 20px;
    }
}
