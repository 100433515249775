@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/mixins/container";
@import '../../assets/styles/extends/section';

/* .search {
    position: relative;
    margin: 48px auto;
    width: 864px;

    @include tablet {
        width:calc(100% - 40px);
    }

    @include mobile {
        width:calc(100% - 40px);
        margin:0 0 0 24px ;
    }

    &__icon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
        color: $color-accent;
    }

    &__field {
        :global input {
            padding-top: 0;
            padding-right: 180px;
            padding-bottom: 0;
        }
    }

    &__submit {

        &:global(.MuiButton-root) {
            position: absolute;
            right: 0;
            top: 0;
            padding-right: 63px;
            padding-left: 63px;
            height: 56px;

            @include mobile {
                position: static;
                width: 100%;
                margin-top: 12px;
            }
        }

    }
}*/

.cards {
    @extend .container;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    margin-top: 48px;
    margin-bottom: 144px;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 32px;
        padding: 0 20px;
    }

    @include mobile {
        display: block;
        margin-top:16px;
        margin-bottom: 96px;
    }

}

.card {
    border: 2px solid transparent;
    padding: 36px;

    &:hover {
        border-color: $color-orange;
    }

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 14px;
        margin-top: 8px;
        flex-direction: row;
        text-align: left;
    }

    div{
        @include mobile {
            margin: 0;
        }
    }

    img{
        margin-right: 0;
        @include mobile {
            width: 40px;
            height: auto;
            border-radius: 8px;
            margin-right: 20px;
        }
    }
}

.questions {
    padding-top: 16px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 528px;
        background: $color-accent-reverse;

        @include tablet {
            height: 670px;
        }

        @include mobile {
            height: 548px;
        }
    }

    @include tablet {
        padding: 48px 0 0 0;
    }

    @include mobile {
        padding-top: 24px;
    }

    &__header {
        text-align: center;
    }

    &__title {
        @extend .section_title;

        margin-bottom: 48px;

        @include mobile {
          max-width: 320px;
          margin: 0 auto 24px;
        }
    }

    &__titlePopular {
        margin: 0;
        margin-bottom: 56px;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.01em;
        text-align: center;
        color: $color-dark;

        @include mobile {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 12px;
        }

        span {
            color: $color-accent;
        }
    }

    &__subtitle {
        @extend .section_subtitle;
        text-transform: uppercase;
    }
}
