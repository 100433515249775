@import "../.././assets/styles/global/settings/variables";


.card {
    position: relative;
    padding: 40px 16px;
    width: 25%;
    //height: 440px;
    border: 1px solid rgba(9, 41, 156, 0.12);
    border-top: 0;
    border-left: 0;
    color: $color-dark;

    .link{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &:hover img {
        transform: scale(1.1);
    }

    &:hover a{
        color: $color-accent;
    }

    @include desktop {
        padding: 20px 6px;
    }

    @include tablet {
        //grid-template-columns: 130px auto;
    }

    @include mobile {
        padding: 32px 0;
        //grid-template-columns: 100%;
        width: 100%;
        border-right: 0;

        a:first-child & {
            padding-top: 0;
        }

        a:last-child & {
            padding-bottom: 0;
        }
    }

    /*&_large {
        flex-direction: row;
        width: 100%;

        .media {
            margin-bottom: 0;
            margin-right: 32px;
            width: 50%;
            height: 100%;
        }
    }

    &_medium {
        flex-direction: row;
        width: 50%;
    }

    &_reverse {
        flex-direction: row-reverse;

        .media {
            margin-bottom: 0;
            margin-left: 32px;
        }
    }*/

    &:first-child{
        width: 100%;

        .link{
            flex-direction: row;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
            }
        }

        .media{
            width: calc(50% - 16px);
            margin: 0;

            @include mobile {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 16px;
            }
        }

        .content {
            width: calc(50% - 16px);

            @include mobile {
                width: 100%;
            }
        }

        .title{
            font-size: 32px;
            line-height: 36px;
            max-width: 530px;

            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .info{
            min-height: 1px;
        }
    }

    &:nth-child(9n+2){
        width: 50%;

        @include mobile {
            width: 100%;
        }

        .media{
            display: none;

            @include mobile {
                display: block;
            }
        }

        .title{
            font-size: 32px;
            line-height: 36px;
            max-width: 530px;

            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .info{
            min-height: 1px;
        }
    }

    &:nth-child(9n){
        width: 100%;

        .link {
            flex-direction: row;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
            }
        }

        .media{
            width: calc(50% - 16px);
            margin-bottom: 0;
            order: 1;

            @include mobile {
                order: 0;
                width: 100%;
                margin: 0 auto 16px;
            }
        }

        .content {
            width: calc(50% - 16px);

            @include mobile {
                width: 100%;
            }
        }

        .title{
            font-size: 32px;
            line-height: 36px;
            max-width: 530px;

            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .info{
            min-height: 1px;
        }
    }

}


.media {
    margin: 0 auto 16px;

    img {
        transition: $trans-def;
        border-radius: 4px;

        @include mobile {
            width: 100%;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.date {
    margin-top: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color-dark, 0.64);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
}

.tag {
    margin-top: 6px;
    margin-right: 6px;
    border: 1px solid rgba($color-accent, 0.4);
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $color-accent;

    //&:hover{
    //    background: $color-accent;
    //    color: $color-white;
    //}

    @include mobile {
        font-size: 13px;
        line-height: 13px;
    }
}

.title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    a{
        color: $color-dark;
    }

    .preview:hover & {
        color: $color-accent;
    }

    @include mobile {
        margin-bottom: 24px;
    }
}
