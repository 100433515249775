@import '../../assets/styles/global/settings/variables';
@import "../../assets/styles/extends/typography";


.container {
    padding: 32px;
    background: $color-white;
    box-shadow: 0 16px 24px rgba(0, 20, 91, 0.02), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);
    border-radius: 8px;
    transition: $trans-def;

    &:hover {
        box-shadow: 0 16px 24px rgba(0, 20, 91, 0.08), 0 2px 6px rgba(0, 20, 91, 0.02), 0 0 1px rgba(0, 20, 91, 0.02);

        @include tablet {
            box-shadow: none;
        }
    }

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 16px;
    }
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @include mobile {
        margin-bottom: 24px;
    }

    &__content {
        width: calc(100% - 96px);

        @include mobile {
            width: calc(100% - 76px);
        }
    }
}

.photo {
    margin-right: 16px;
    border-radius: 50%;
    object-fit: contain;
    width: 56px;
    height: 56px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }

    @include mobile {
        width: 48px;
        height: 48px;
    }
}

.name {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $color-dark;

    &__verified {
        margin-left: 5px;

        path {
            stroke: $color-white;
            fill: $color-accent;
        }
    }

    @include mobile {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 20px;
    }
}

.position {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba($color: $color-dark, $alpha: 0.8);

    @include mobile {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 14px;
    }
}

.rating {
    display: flex;
    align-items: center;

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color: $color-dark, $alpha: 0.64)
    }

    &__number {
        margin-right: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: $color-dark;
    }

    &__star {
        margin-left: 12px;
        margin-top: -2px;
        width: 16px;
        height: 16px;

        path {
            fill: $color-accent;
        }
    }
}

.main {
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 4px;
        }
    }

    &__skills {
        margin-bottom: 16px;

        @include mobile {
            margin-bottom: 12px;
        }

        &_label {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: rgba($color: $color-dark, $alpha: 0.8);

            @include mobile {
                font-size: 12px;
                line-height: 13px;
            }
        }

        & > div {
            flex-wrap: wrap;

            div {
                margin: 0 8px 8px 0 !important;
            }
        }
    }
}

.job,
.location {
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba($color: $color-dark, $alpha: 0.72);

    svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        color: transparent;
    }

    @include mobile {
        margin-right: 0;
        margin-bottom: 12px;
    }
}

.desc {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $color-dark;

    @include mobile {
        margin-bottom: 16px;
    }
}

.footer {
    margin-top: 32px;
}

.button {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 240px;
}
