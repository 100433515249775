@import "../.././../assets/styles/global/settings/variables";


.content_body {
    position: relative;
    width: 100%;
    flex: 1;
    margin-bottom: 24px;
}

.scroller {
    margin-top: 40px;
    min-height: inherit;

    @include tablet {
        margin-top: 64px;
    }

    @include mobile {
        margin-top: 54px;
    }
}

.group {
    margin-top: 12px;

    &__status {
        margin-top: 0;
    }
}

.day {
    position: sticky;
    top: 10px;
    bottom: -32px;
    z-index: 2;
    margin: 32px auto;
    padding: 4px 8px;
    width: max-content;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba($color-dark, 0.48);
    background-color: $color-white;
}

.dialog {
    display: flex;
    align-items: flex-end;

    &__list {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        // max-width: calc(100% - 40px);
    }
}

.session__text {
    margin-bottom: 8px;
}
