.container {
    position: relative;
    z-index: 13;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    cursor: pointer;

    svg {
        width: 24px;
        height: 24px;
    }
}
