@font-face {
    font-family: "SFProDisplay";
    src:
        url("../../../fonts/SFProDisplay/SFProDisplay-Light.woff2");
    font-weight: 300;
}

@font-face {
    font-family: "SFProDisplay";
    src:
url("../../../fonts/SFProDisplay/SFProDisplay-Regular.woff2");
    font-weight: 400;
}

@font-face {
    font-family: "SFProDisplay";
    src:
        url("../../../fonts/SFProDisplay/SFProDisplay-Medium.woff2");
    font-weight: 500;
}

@font-face {
    font-family: "SFProDisplay";
    src:
url("../../../fonts/SFProDisplay/SFProDisplay-Semibold.woff2");
    font-weight: 600;
}

@font-face {
    font-family: "SFProDisplay";
    src:
        url("../../../fonts/SFProDisplay/SFProDisplay-Bold.woff2");
    font-weight: 700;
}

@font-face {
    font-family: "SFProDisplay";
    src:
        url("../../../fonts/SFProDisplay/SFProDisplay-Black.woff2");
    font-weight: 900;
}

